:root {
  --base-color: #d3d3d3;
  --background-color: #ffffff;
  --card-color: #fafafa;
  --text-color: #212121;
  --transition: 0.3s;
  --base-color-rgb: 211, 211, 211;
  --background-color-rgb: 255, 255, 255;
  --card-color-rgb: 250, 250, 250;
  --text-color-rgb: 33, 33, 33;
}

@-webkit-keyframes toast-anim {
  0% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toast-anim {
  0% {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.palette_seekbar,
.solid_alpha_seekbar,
.solid_red_seekbar,
.solid_green_seekbar,
.solid_blue_seekbar,
.gradient_top_alpha_seekbar,
.gradient_top_red_seekbar,
.gradient_top_green_seekbar,
.gradient_top_blue_seekbar,
.gradient_bottom_alpha_seekbar,
.gradient_bottom_red_seekbar,
.gradient_bottom_green_seekbar,
.gradient_bottom_blue_seekbar {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  outline: none;
  border-radius: 8px;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2);
          box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2);
}

/* seekbar thumb:chrome **/
.palette_seekbar::-webkit-slider-thumb,
.solid_alpha_seekbar::-webkit-slider-thumb,
.solid_red_seekbar::-webkit-slider-thumb,
.solid_green_seekbar::-webkit-slider-thumb,
.solid_blue_seekbar::-webkit-slider-thumb,
.gradient_top_alpha_seekbar::-webkit-slider-thumb,
.gradient_top_red_seekbar::-webkit-slider-thumb,
.gradient_top_green_seekbar::-webkit-slider-thumb,
.gradient_top_blue_seekbar::-webkit-slider-thumb,
.gradient_bottom_alpha_seekbar::-webkit-slider-thumb,
.gradient_bottom_red_seekbar::-webkit-slider-thumb,
.gradient_bottom_green_seekbar::-webkit-slider-thumb,
.gradient_bottom_blue_seekbar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.palette_seekbar::-webkit-slider-thumb:hover,
.solid_alpha_seekbar::-webkit-slider-thumb:hover,
.solid_red_seekbar::-webkit-slider-thumb:hover,
.solid_green_seekbar::-webkit-slider-thumb:hover,
.solid_blue_seekbar::-webkit-slider-thumb:hover,
.gradient_top_alpha_seekbar::-webkit-slider-thumb:hover,
.gradient_top_red_seekbar::-webkit-slider-thumb:hover,
.gradient_top_green_seekbar::-webkit-slider-thumb:hover,
.gradient_top_blue_seekbar::-webkit-slider-thumb:hover,
.gradient_bottom_alpha_seekbar::-webkit-slider-thumb:hover,
.gradient_bottom_red_seekbar::-webkit-slider-thumb:hover,
.gradient_bottom_green_seekbar::-webkit-slider-thumb:hover,
.gradient_bottom_blue_seekbar::-webkit-slider-thumb:hover {
  width: 17px;
  height: 17px;
}

.palette_seekbar::-webkit-slider-thumb,
.gradient_top_alpha_seekbar::-webkit-slider-thumb,
.gradient_bottom_alpha_seekbar::-webkit-slider-thumb,
.solid_alpha_seekbar::-webkit-slider-thumb {
  background-color: var(--text-color);
}

.gradient_top_red_seekbar::-webkit-slider-thumb,
.gradient_bottom_red_seekbar::-webkit-slider-thumb,
.solid_red_seekbar::-webkit-slider-thumb {
  background-color: #d32f2f;
}

.gradient_top_green_seekbar::-webkit-slider-thumb,
.gradient_bottom_green_seekbar::-webkit-slider-thumb,
.solid_green_seekbar::-webkit-slider-thumb {
  background-color: #388e3c;
}

.gradient_top_blue_seekbar::-webkit-slider-thumb,
.gradient_bottom_blue_seekbar::-webkit-slider-thumb,
.solid_blue_seekbar::-webkit-slider-thumb {
  background-color: #1976d2;
}

/* seekbar thumb:firefox **/
.palette_seekbar::-moz-range-thumb,
.solid_alpha_seekbar::-moz-range-thumb,
.solid_red_seekbar::-moz-range-thumb,
.solid_green_seekbar::-moz-range-thumb,
.solid_blue_seekbar::-moz-range-thumb,
.gradient_top_alpha_seekbar::-moz-range-thumb,
.gradient_top_red_seekbar::-moz-range-thumb,
.gradient_top_green_seekbar::-moz-range-thumb,
.gradient_top_blue_seekbar::-moz-range-thumb,
.gradient_bottom_alpha_seekbar::-moz-range-thumb,
.gradient_bottom_red_seekbar::-moz-range-thumb,
.gradient_bottom_green_seekbar::-moz-range-thumb,
.gradient_bottom_blue_seekbar::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.palette_seekbar::-moz-range-thumb:hover,
.solid_alpha_seekbar::-moz-range-thumb:hover,
.solid_red_seekbar::-moz-range-thumb:hover,
.solid_green_seekbar::-moz-range-thumb:hover,
.solid_blue_seekbar::-moz-range-thumb:hover,
.gradient_top_alpha_seekbar::-moz-range-thumb:hover,
.gradient_top_red_seekbar::-moz-range-thumb:hover,
.gradient_top_green_seekbar::-moz-range-thumb:hover,
.gradient_top_blue_seekbar::-moz-range-thumb:hover,
.gradient_bottom_alpha_seekbar::-moz-range-thumb:hover,
.gradient_bottom_red_seekbar::-moz-range-thumb:hover,
.gradient_bottom_green_seekbar::-moz-range-thumb:hover,
.gradient_bottom_blue_seekbar::-moz-range-thumb:hover {
  width: 17px;
  height: 17px;
}

.palette_seekbar::-moz-range-thumb,
.gradient_top_alpha_seekbar::-moz-range-thumb,
.gradient_bottom_alpha_seekbar::-moz-range-thumb,
.solid_alpha_seekbar::-moz-range-thumb {
  background-color: var(--text-color);
}

.gradient_top_red_seekbar::-moz-range-thumb,
.gradient_bottom_red_seekbar::-moz-range-thumb,
.solid_red_seekbar::-moz-range-thumb {
  background-color: #d32f2f;
}

.gradient_top_green_seekbar::-moz-range-thumb,
.gradient_bottom_green_seekbar::-moz-range-thumb,
.solid_green_seekbar::-moz-range-thumb {
  background-color: #388e3c;
}

.gradient_top_blue_seekbar::-moz-range-thumb,
.gradient_bottom_blue_seekbar::-moz-range-thumb,
.solid_blue_seekbar::-moz-range-thumb {
  background-color: #1976d2;
}

.toggle-checkbox {
  position: relative;
  width: 40px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
}

.toggle-checkbox::before {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  background-color: var(--card-color);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.toggle-checkbox:checked {
  background-color: rgba(var(--text-color-rgb), 0.6);
}

.toggle-checkbox:checked::before {
  left: 22.5px;
  background-color: var(--text-color);
}

html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--text-color);
  background-color: var(--base-color);
}

html h1,
html h2,
html h3,
html h4,
html h5,
html p,
body h1,
body h2,
body h3,
body h4,
body h5,
body p {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

html ul,
html li,
html img,
html select,
body ul,
body li,
body img,
body select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
}

.mobile-warning {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--background-color);
}

.mobile-warning .warning-body {
  margin: 32px;
}

.mobile-warning .warning-body img {
  width: 48px;
  height: 48px;
}

.mobile-warning .warning-body h1 {
  margin-top: 8px;
}

.mobile-warning .warning-body p {
  margin-top: 10px;
}

.mobile-warning .warning-body p a {
  font-weight: 600;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.container .menu {
  width: 96px;
  height: 100vh;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: var(--background-color);
}

.container .menu div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container .menu div .logo {
  cursor: pointer;
  margin-top: 24px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container .menu div .logo:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container .menu div ul {
  padding: 0;
  margin: 60px 0 0 0;
  list-style: none;
}

.container .menu div ul li {
  margin-bottom: 40px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
}

.container .menu div ul li:hover {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container .menu div ul .home-item {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container .menu div ul .create-item {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container .menu div ul .extract-item {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container .menu div ul .harmonizer-item {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container .menu div ul .saved-item {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container .menu .settings {
  cursor: pointer;
  margin-bottom: 50px;
  -ms-grid-column-align: end;
      justify-self: end;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container .menu .settings:hover {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin-left: 1px;
}

.container section .header {
  width: 100%;
  height: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: var(--background-color);
}

.container section .header div {
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.container section .header div h1 {
  color: var(--text-color);
  font-size: 32px;
  font-weight: 900;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .header div p {
  color: #808080;
  font-weight: 900;
  font-size: 14px;
  margin: 0 0 4px 8px;
}

.container section .header img {
  cursor: pointer;
  margin-right: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container section .header img:hover {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section .material-color {
  width: 100%;
  height: 100%;
  margin-top: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.container section .material-color .color-shades {
  width: 100%;
  height: auto;
  padding: 16px 20px;
  display: -ms-grid;
  display: grid;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  background-color: var(--background-color);
}

.container section .material-color .color-shades .color-shade {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin: 16px 20px;
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.container section .material-color .color-shades .color-shade div:nth-child(1) {
  width: 100%;
  height: 74%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.container section .material-color .color-shades .color-shade div:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 5px 20px;
}

.container section .material-color .color-shades .color-shade div:nth-child(2) div p:nth-child(1) {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 2px;
  color: var(--text-color);
}

.container section .material-color .color-shades .color-shade div:nth-child(2) div p:nth-child(2) {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
}

.container section .material-color .color-shades .color-shade div:nth-child(2) img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .material-color .color-shades .color-shade div:nth-child(2) img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container section .material-color .color-shades .color-shade:hover {
  -webkit-filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
}

.container section .material-color .color-shades .color-shade:hover div:nth-child(1) {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.15));
}

.container section .material-color .color-holder {
  width: 218px;
  padding: 12px;
  margin-left: 1px;
  display: -ms-grid;
  display: grid;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  grid-auto-rows: 72px;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  background-color: var(--background-color);
  overflow-y: auto;
  overflow-x: hidden;
}

.container section .material-color .color-holder::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .material-color .color-holder::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .material-color .color-holder::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .material-color .color-holder .color-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 16px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 8px 10px 8px 10px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container section .material-color .color-holder .color-item .selector {
  width: 10px;
  height: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  margin: 0 10px 10px 0;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container section .material-color .color-holder .color-item:hover {
  border-radius: 20px;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section .material-color .color-holder .color-item:hover .selector {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section .create-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1px;
}

.container section .create-color .solid-section {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--background-color);
}

.container section .create-color .solid-section .solid-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .create-color .solid-section .solid-header .solid-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .create-color .solid-section .solid-header .solid-header-title p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .create-color .solid-section .solid-header .solid-alpha-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .create-color .solid-section .solid-header .solid-alpha-control p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  margin-right: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .create-color .solid-section .solid-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 32px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .create-color .solid-section .solid-body .solid-color {
  width: 55%;
  height: 100%;
  cursor: pointer;
  border-radius: inherit;
  -webkit-filter: drop-shadow(4px 0 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(4px 0 8px rgba(0, 0, 0, 0.2));
}

.container section .create-color .solid-section .solid-body .solid-color:hover {
  -webkit-transition: var(--transition) ease-in-out;
  transition: var(--transition) ease-in-out;
  -webkit-filter: drop-shadow(2px 0 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(2px 0 4px rgba(0, 0, 0, 0.1));
}

.container section .create-color .solid-section .solid-body .solid-control {
  width: 45%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: var(--text-color);
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes {
  margin: 32px;
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hex div img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hex div img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hex div img:nth-child(2) {
  margin-left: 16px;
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-rgb div img:nth-child(2) {
  margin-left: 16px;
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-rgb,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hsv,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hsl,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-cmyk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-rgb img,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hsv img,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hsl img,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-cmyk img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-rgb img:hover,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hsv img:hover,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-hsl img:hover,
.container section .create-color .solid-section .solid-body .solid-control .solid-attributes .solid-cmyk img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container section .create-color .solid-section .solid-body .solid-control .seekbars {
  margin-bottom: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.container section .create-color .solid-section .solid-body .solid-control .seekbars ul:nth-child(1) {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.container section .create-color .solid-section .solid-body .solid-control .seekbars ul:nth-child(1) li {
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: bold;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  list-style-type: none;
}

.container section .create-color .solid-section .solid-body .solid-control .seekbars ul:nth-child(2) {
  margin: 0 0 0 10px;
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.container section .create-color .solid-section .solid-body .solid-control .seekbars ul:nth-child(2) li {
  list-style-type: none;
}

.container section .create-color .solid-section .solid-body .solid-control .seekbars ul:nth-child(2) li input[type='range'] {
  width: 88%;
}

.container section .create-color .gradient-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--background-color);
}

.container section .create-color .gradient-section .gradient-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .create-color .gradient-section .gradient-header .gradient-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 32px 0 0 32px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .create-color .gradient-section .gradient-header .gradient-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .create-color .gradient-section .gradient-header .gradient-alpha-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .create-color .gradient-section .gradient-header .gradient-alpha-control p {
  font-size: 20px;
  margin-right: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .create-color .gradient-section .gradient-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 32px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .create-color .gradient-section .gradient-body .gradient-color {
  width: 55%;
  height: 100%;
  cursor: pointer;
  border-radius: inherit;
  -webkit-filter: drop-shadow(4px 0 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(4px 0 8px rgba(0, 0, 0, 0.2));
}

.container section .create-color .gradient-section .gradient-body .gradient-color:hover {
  -webkit-transition: var(--transition) ease-in-out;
  transition: var(--transition) ease-in-out;
  -webkit-filter: drop-shadow(2px 0 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(2px 0 4px rgba(0, 0, 0, 0.1));
}

.container section .create-color .gradient-section .gradient-body .gradient-control {
  width: 45%;
  height: 100%;
  margin: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: var(--text-color);
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom {
  margin: 16px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-hex,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-hex,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-hex,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-hex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-hex div img,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-hex div img,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-hex div img,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-hex div img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-hex div img:hover,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-hex div img:hover,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-hex div img:hover,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-hex div img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-hex div img:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-hex div img:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-hex div img:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-hex div img:nth-child(2) {
  margin-left: 16px;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-rgba,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-rgba,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-rgba,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-rgba {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-rgba div img,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-rgba div img,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-rgba div img,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-rgba div img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-rgba div img:hover,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-rgba div img:hover,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-rgba div img:hover,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-rgba div img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-rgba div img:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-rgba div img:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-rgba div img:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-rgba div img:nth-child(2) {
  margin-left: 16px;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-sliders,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-sliders,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-sliders,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-sliders {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-sliders ul:nth-child(1),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-sliders ul:nth-child(1),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-sliders ul:nth-child(1),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-sliders ul:nth-child(1) {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-sliders ul:nth-child(1) li,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-sliders ul:nth-child(1) li,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-sliders ul:nth-child(1) li,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-sliders ul:nth-child(1) li {
  font-size: 18px;
  font-weight: bold;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  list-style-type: none;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-sliders ul:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-sliders ul:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-sliders ul:nth-child(2),
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-sliders ul:nth-child(2) {
  margin: 0 0 0 14px;
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-sliders ul:nth-child(2) li,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-sliders ul:nth-child(2) li,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-sliders ul:nth-child(2) li,
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-sliders ul:nth-child(2) li {
  list-style-type: none;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-top-sliders ul:nth-child(2) li input[type='range'],
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top .gradient-bottom-sliders ul:nth-child(2) li input[type='range'],
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-top-sliders ul:nth-child(2) li input[type='range'],
.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom .gradient-bottom-sliders ul:nth-child(2) li input[type='range'] {
  width: 100%;
}

.container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom {
  margin: 16px 16px 24px 16px;
}

.container section .extract-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1px;
  overflow: hidden;
}

.container section .extract-color .swatch-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow-y: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-behavior: smooth !important;
  background-color: var(--background-color);
}

.container section .extract-color .swatch-section::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .extract-color .swatch-section::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .extract-color .swatch-section::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .extract-color .swatch-section .swatch-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .extract-color .swatch-section .swatch-header .swatch-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .extract-color .swatch-section .swatch-header .swatch-header-title p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .extract-color .swatch-section .swatch-header .swatch-reset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .extract-color .swatch-section .swatch-header .swatch-reset p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  margin-right: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .extract-color .swatch-section .swatch-header .swatch-reset img {
  height: 28px;
  width: 28px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container section .extract-color .swatch-section .swatch-header .swatch-reset img:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.container section .extract-color .swatch-section .swatch-body {
  margin: 32px 32px 0 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser {
  width: 100%;
  min-height: 256px;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  aspect-ratio: 3/1.5;
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser:hover {
  -webkit-transition: var(--transition) ease-in-out;
  transition: var(--transition) ease-in-out;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .selected-image {
  position: fixed;
  max-height: 100%;
  max-width: 100%;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .logo-info {
  width: 100%;
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .logo-info img {
  width: 40px;
  aspect-ratio: 1/1;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .logo-info p {
  font-weight: 700;
  color: #808080;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 16px 0 0 8px;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .save-swatch,
.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .image-url {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  margin: 12px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .save-swatch img,
.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .image-url img {
  width: 16px;
  height: 16px;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .save-swatch:hover,
.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .image-url:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .save-swatch {
  margin-right: 56px;
}

.container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .save-swatch img {
  width: 20px;
  height: 20px;
}

.container section .extract-color .swatch-section .swatch-body .extracted-swatches {
  width: 95%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section .extract-color .swatch-section .swatch-body .swatch-extract-info {
  width: 95%;
  color: #808080;
}

.container section .extract-color .swatch-section .swatch-body .swatch-extract-info p {
  font-weight: 700;
  font-size: larger;
}

.container section .extract-color .swatch-section .swatch-body .swatch-extract-info ul li {
  font-size: large;
  font-weight: 600;
  margin-top: 12px;
}

.container section .extract-color .palette-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow-y: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-behavior: smooth !important;
  background-color: var(--background-color);
}

.container section .extract-color .palette-section::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .extract-color .palette-section::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .extract-color .palette-section::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .extract-color .palette-section .palette-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .extract-color .palette-section .palette-header .palette-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .extract-color .palette-section .palette-header .palette-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .extract-color .palette-section .palette-header .palette-reset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .extract-color .palette-section .palette-header .palette-reset p {
  font-size: 20px;
  margin-right: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .extract-color .palette-section .palette-header .palette-reset img {
  height: 28px;
  width: 28px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container section .extract-color .palette-section .palette-header .palette-reset img:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.container section .extract-color .palette-section .palette-body {
  margin: 32px 32px 24px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser {
  width: 100%;
  min-height: 256px;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  aspect-ratio: 3/1.5;
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser:hover {
  -webkit-transition: var(--transition) ease-in-out;
  transition: var(--transition) ease-in-out;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .selected-image {
  position: fixed;
  max-height: 100%;
  max-width: 100%;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .logo-info {
  width: 100%;
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .logo-info img {
  width: 40px;
  aspect-ratio: 1/1;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .logo-info p {
  font-weight: 700;
  color: #808080;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 16px 0 0 8px;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .save-palette,
.container section .extract-color .palette-section .palette-body .palette-img-chooser .image-url {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  margin: 12px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .save-palette img,
.container section .extract-color .palette-section .palette-body .palette-img-chooser .image-url img {
  width: 16px;
  height: 16px;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .save-palette:hover,
.container section .extract-color .palette-section .palette-body .palette-img-chooser .image-url:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .save-palette {
  margin-right: 56px;
}

.container section .extract-color .palette-section .palette-body .palette-img-chooser .save-palette img {
  width: 20px;
  height: 20px;
}

.container section .extract-color .palette-section .palette-body .extracted-palette {
  gap: 20px;
  width: 95%;
  display: -ms-grid;
  display: grid;
  margin-top: 24px;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.container section .extract-color .palette-section .palette-body .palette-extract-info {
  width: 95%;
  color: #808080;
}

.container section .extract-color .palette-section .palette-body .palette-extract-info p {
  font-weight: 700;
  font-size: larger;
}

.container section .extract-color .palette-section .palette-body .palette-extract-info ul li {
  font-size: large;
  font-weight: 600;
  margin-top: 12px;
}

.container section .extract-color .manual-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow-y: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-behavior: smooth !important;
  background-color: var(--background-color);
}

.container section .extract-color .manual-section::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .extract-color .manual-section::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .extract-color .manual-section::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .extract-color .manual-section .manual-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .extract-color .manual-section .manual-header .manual-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .extract-color .manual-section .manual-header .manual-header-title p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .extract-color .manual-section .manual-header .manual-reset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .extract-color .manual-section .manual-header .manual-reset p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  margin-right: 10px;
  font-weight: bold;
  color: var(--text-color);
}

.container section .extract-color .manual-section .manual-header .manual-reset img {
  height: 28px;
  width: 28px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
}

.container section .extract-color .manual-section .manual-header .manual-reset img:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.container section .extract-color .manual-section .manual-body {
  margin: 32px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section .extract-color .manual-section .manual-body .manual-img-background {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 26px 26px 24px 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-img-card {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-img-card .manual-select-logo {
  width: 40px;
  height: 40px;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-img-card canvas {
  width: 100%;
  position: absolute;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-img-card:hover {
  -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-img-card .image-url {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  margin: 12px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-img-card .image-url img {
  width: 16px;
  height: 16px;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-img-card .image-url:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card {
  margin: 20px 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--text-color);
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card p {
  font-size: larger;
  font-weight: 800;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-hex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-hex img {
  margin-left: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-hex img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-choose-image {
  width: 42px;
  height: 42px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-choose-image img {
  width: 22px;
  height: 22px;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-choose-image:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-rgb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-rgb img {
  margin-left: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-rgb img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.container section .extract-color .manual-section .manual-body .manual-img-background:hover {
  cursor: pointer;
}

.container section .harmonize-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1px;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth !important;
}

.container section .harmonize-color::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .harmonize-color::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .harmonize-color::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .harmonize-color .complement-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--background-color);
}

.container section .harmonize-color .complement-container .complement-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .complement-container .complement-header .complement-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .harmonize-color .complement-container .complement-header .complement-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .complement-container .complement-body {
  height: 100%;
  margin: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .complement-container .complement-body .colors-container {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .complement-container .complement-body .colors-container .colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: inherit;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .harmonize-color .complement-container .complement-body .colors-container .colors div {
  width: 100%;
  height: 100%;
}

.container section .harmonize-color .complement-container .complement-body .colors-container .codes {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--text-color);
  margin: 12px 0;
}

.container section .harmonize-color .complement-container .complement-body .colors-container .codes div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .harmonize-color .complement-container .complement-body .colors-container .codes div p {
  cursor: pointer;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .complement-container .complement-body .colors-container .codes div p:nth-child(2) {
  margin-top: 2px;
}

.container section .harmonize-color .complement-container .complement-body .colors-container .separator {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--base-color);
}

.container section .harmonize-color .complement-container .complement-body .colors-container .info {
  margin: 14px;
  font-size: 14px;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--text-color);
  margin: 8px 16px 16px 16px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode p {
  font-size: medium;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode input {
  margin: 4px 0 0 10px;
}

.container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-complement {
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 24px;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
}

.container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-complement img {
  width: 20px;
  height: 20px;
}

.container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-complement:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .complement-container .complement-body .colors-container:hover {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .complement-container .complement-body .colors-container:hover .colors {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .complement-container .complement-body .color-wheel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.container section .harmonize-color .complement-container .complement-body .color-wheel:hover {
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

.container section .harmonize-color .complement-container .complement-body .color-wheel img {
  width: 10px;
  height: 10px;
  aspect-ratio: 1/1;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
}

.container section .harmonize-color .split-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--background-color);
}

.container section .harmonize-color .split-container .split-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .split-container .split-header .split-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .harmonize-color .split-container .split-header .split-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .split-container .split-body {
  height: 100%;
  margin: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .split-container .split-body .colors-container {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .split-container .split-body .colors-container .colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: inherit;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .harmonize-color .split-container .split-body .colors-container .colors div {
  width: 100%;
  height: 100%;
}

.container section .harmonize-color .split-container .split-body .colors-container .codes {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--text-color);
  margin: 12px 0;
}

.container section .harmonize-color .split-container .split-body .colors-container .codes div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .harmonize-color .split-container .split-body .colors-container .codes div p {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .split-container .split-body .colors-container .codes div p:nth-child(2) {
  margin-top: 4px;
}

.container section .harmonize-color .split-container .split-body .colors-container .separator {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--base-color);
}

.container section .harmonize-color .split-container .split-body .colors-container .info {
  margin: 14px;
  font-size: 14px;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .split-container .split-body .colors-container .invert-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--text-color);
  margin: 8px 16px 16px 16px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .harmonize-color .split-container .split-body .colors-container .invert-mode p {
  font-size: medium;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .split-container .split-body .colors-container .invert-mode input {
  margin: 4px 0 0 10px;
}

.container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-split {
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 24px;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
}

.container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-split img {
  width: 20px;
  height: 20px;
}

.container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-split:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .split-container .split-body .colors-container:hover {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .split-container .split-body .colors-container:hover .colors {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .split-container .split-body .color-wheel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.container section .harmonize-color .split-container .split-body .color-wheel:hover {
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

.container section .harmonize-color .split-container .split-body .color-wheel img {
  width: 10px;
  height: 10px;
  aspect-ratio: 1/1;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
}

.container section .harmonize-color .analogous-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--background-color);
}

.container section .harmonize-color .analogous-container .analogous-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .analogous-container .analogous-header .analogous-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .harmonize-color .analogous-container .analogous-header .analogous-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .analogous-container .analogous-body {
  height: 100%;
  margin: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: inherit;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .colors div {
  width: 100%;
  height: 100%;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .codes {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--text-color);
  margin: 12px 0;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .codes div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .codes div p {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .codes div p:nth-child(2) {
  margin-top: 4px;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .separator {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--base-color);
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .info {
  margin: 14px;
  font-size: 14px;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--text-color);
  margin: 8px 16px 16px 16px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode p {
  font-size: medium;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode input {
  margin: 4px 0 0 10px;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-analogous {
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 24px;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-analogous img {
  width: 20px;
  height: 20px;
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-analogous:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container:hover {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .analogous-container .analogous-body .colors-container:hover .colors {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .analogous-container .analogous-body .color-wheel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.container section .harmonize-color .analogous-container .analogous-body .color-wheel:hover {
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

.container section .harmonize-color .analogous-container .analogous-body .color-wheel img {
  width: 10px;
  height: 10px;
  aspect-ratio: 1/1;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
}

.container section .harmonize-color .triadic-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--background-color);
}

.container section .harmonize-color .triadic-container .triadic-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .triadic-container .triadic-header .triadic-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .harmonize-color .triadic-container .triadic-header .triadic-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .triadic-container .triadic-body {
  height: 100%;
  margin: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: inherit;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .colors div {
  width: 100%;
  height: 100%;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .codes {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--text-color);
  margin: 12px 0;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .codes div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .codes div p {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .codes div p:nth-child(2) {
  margin-top: 4px;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .separator {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--base-color);
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .info {
  margin: 14px;
  font-size: 14px;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--text-color);
  margin: 8px 16px 16px 16px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode p {
  font-size: medium;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode input {
  margin: 4px 0 0 10px;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-triadic {
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 24px;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-triadic img {
  width: 20px;
  height: 20px;
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-triadic:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container:hover {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .triadic-container .triadic-body .colors-container:hover .colors {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .triadic-container .triadic-body .color-wheel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.container section .harmonize-color .triadic-container .triadic-body .color-wheel:hover {
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

.container section .harmonize-color .triadic-container .triadic-body .color-wheel img {
  width: 10px;
  height: 10px;
  aspect-ratio: 1/1;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
}

.container section .harmonize-color .tetradic-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--background-color);
}

.container section .harmonize-color .tetradic-container .tetradic-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .tetradic-container .tetradic-header .tetradic-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .harmonize-color .tetradic-container .tetradic-header .tetradic-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .tetradic-container .tetradic-body {
  height: 100%;
  margin: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: inherit;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .colors div {
  width: 100%;
  height: 100%;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--text-color);
  margin: 12px 0;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes div p {
  cursor: pointer;
  font-size: 11px;
  font-weight: 800;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes div p:nth-child(2) {
  margin-top: 6px;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .separator {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--base-color);
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .info {
  margin: 14px;
  font-size: 14px;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--text-color);
  margin: 8px 16px 16px 16px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode p {
  font-size: medium;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode input {
  margin: 4px 0 0 10px;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-tetradic {
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 24px;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-tetradic img {
  width: 20px;
  height: 20px;
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-tetradic:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container:hover {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .tetradic-container .tetradic-body .colors-container:hover .colors {
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.container section .harmonize-color .tetradic-container .tetradic-body .color-wheel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  aspect-ratio: 1/1;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.container section .harmonize-color .tetradic-container .tetradic-body .color-wheel:hover {
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}

.container section .harmonize-color .tetradic-container .tetradic-body .color-wheel img {
  width: 10px;
  height: 10px;
  aspect-ratio: 1/1;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
}

.container section .saved-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1px;
  overflow: hidden;
}

.container section .saved-color .solgrad-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-behavior: smooth !important;
  background-color: var(--background-color);
}

.container section .saved-color .solgrad-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .saved-color .solgrad-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .saved-color .solgrad-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .saved-color .solgrad-container .solgrad-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .saved-color .solgrad-container .solgrad-header .solgrad-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .saved-color .solgrad-container .solgrad-header .solgrad-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .saved-color .solgrad-container .solgrad-header .solgrad-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .saved-color .solgrad-container .solgrad-header .solgrad-options select {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 12px;
  color: var(--text-color);
  background-color: var(--card-color);
  font-family: 'Quicksand', sans-serif;
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.container section .saved-color .solgrad-container .solgrad-header .solgrad-options select:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .saved-color .solgrad-container .solgrad-solid-items {
  gap: 20px;
  margin: 32px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.container section .saved-color .solgrad-container .solgrad-gradient-items {
  margin: 32px 32px 10px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section .saved-color .solgrad-container .solgrad-loading {
  margin: 32px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .saved-color .solgrad-container .solgrad-loading p {
  font-size: large;
  font-weight: 700;
  opacity: 0.8;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .saved-color .extraction-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-behavior: smooth !important;
  background-color: var(--background-color);
}

.container section .saved-color .extraction-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .saved-color .extraction-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .saved-color .extraction-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .saved-color .extraction-container .extraction-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .saved-color .extraction-container .extraction-header .extraction-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .saved-color .extraction-container .extraction-header .extraction-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .saved-color .extraction-container .extraction-header .extraction-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .saved-color .extraction-container .extraction-header .extraction-options select {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 12px;
  color: var(--text-color);
  background-color: var(--card-color);
  font-family: 'Quicksand', sans-serif;
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.container section .saved-color .extraction-container .extraction-header .extraction-options select:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .saved-color .extraction-container .extraction-items {
  gap: 20px;
  margin: 32px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.container section .saved-color .extraction-container .extraction-items img {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 3/2;
  border-radius: 24px;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.container section .saved-color .extraction-container .extraction-items img:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .saved-color .extraction-container .extraction-loading {
  margin: 32px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .saved-color .extraction-container .extraction-loading p {
  font-size: large;
  font-weight: 700;
  opacity: 0.8;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .saved-color .harmony-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-behavior: smooth !important;
  background-color: var(--background-color);
}

.container section .saved-color .harmony-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .saved-color .harmony-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .saved-color .harmony-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .saved-color .harmony-container .harmony-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .saved-color .harmony-container .harmony-header .harmony-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .saved-color .harmony-container .harmony-header .harmony-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .saved-color .harmony-container .harmony-header .harmony-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .saved-color .harmony-container .harmony-header .harmony-options select {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 12px;
  color: var(--text-color);
  background-color: var(--card-color);
  font-family: 'Quicksand', sans-serif;
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.container section .saved-color .harmony-container .harmony-header .harmony-options select:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .saved-color .harmony-container .data-items {
  margin: 32px 32px 10px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section .saved-color .harmony-container .data-loading {
  margin: 32px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container section .saved-color .harmony-container .data-loading p {
  font-size: large;
  font-weight: 700;
  opacity: 0.8;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .saved-color .preset-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  scroll-behavior: smooth !important;
  background-color: var(--background-color);
}

.container section .saved-color .preset-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.container section .saved-color .preset-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.container section .saved-color .preset-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.container section .saved-color .preset-container .preset-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.container section .saved-color .preset-container .preset-header .preset-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 0 0 32px;
}

.container section .saved-color .preset-container .preset-header .preset-header-title p {
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section .saved-color .preset-container .preset-header .preset-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 32px 32px 0 0;
}

.container section .saved-color .preset-container .preset-header .preset-options select {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 12px;
  color: var(--text-color);
  background-color: var(--card-color);
  font-family: 'Quicksand', sans-serif;
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.container section .saved-color .preset-container .preset-header .preset-options select:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.container section .saved-color .preset-container .preset-gradient-items {
  margin: 32px 32px 10px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section .saved-color .preset-container .preset-quadrant-items {
  margin: 32px 32px 10px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container section footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 72px;
  margin-top: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: var(--background-color);
}

.container section footer p {
  color: #808080;
  font-size: 18px;
  font-weight: 600;
  margin-left: 50px;
}

.container section footer p span {
  color: #606060;
  font-weight: 900;
  cursor: pointer;
}

.container section footer ul {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 8px 50px 0 0;
}

.container section footer ul li {
  cursor: pointer;
  margin-left: 24px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.container section footer ul li:nth-child(1) {
  -webkit-filter: drop-shadow(0 4px 4px rgba(14, 118, 168, 0.5));
          filter: drop-shadow(0 4px 4px rgba(14, 118, 168, 0.5));
}

.container section footer ul li:nth-child(1):hover {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section footer ul li:nth-child(2) {
  -webkit-filter: drop-shadow(0 4px 4px rgba(23, 21, 21, 0.5));
          filter: drop-shadow(0 4px 4px rgba(23, 21, 21, 0.5));
}

.container section footer ul li:nth-child(2):hover {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section footer ul li:nth-child(3) {
  -webkit-filter: drop-shadow(0 4px 4px rgba(255, 51, 51, 0.5));
          filter: drop-shadow(0 4px 4px rgba(255, 51, 51, 0.5));
}

.container section footer ul li:nth-child(3):hover {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section footer ul li:nth-child(4) {
  -webkit-filter: drop-shadow(0 4px 4px rgba(59, 89, 152, 0.5));
          filter: drop-shadow(0 4px 4px rgba(59, 89, 152, 0.5));
}

.container section footer ul li:nth-child(4):hover {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section footer ul li:nth-child(5) {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 172, 238, 0.5));
          filter: drop-shadow(0 4px 4px rgba(0, 172, 238, 0.5));
}

.container section footer ul li:nth-child(5):hover {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.container section footer ul li:nth-child(6) {
  -webkit-filter: drop-shadow(0 4px 4px rgba(255, 51, 51, 0.5));
          filter: drop-shadow(0 4px 4px rgba(255, 51, 51, 0.5));
}

.container section footer ul li:nth-child(6):hover {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0));
}

.solid-item {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px 24px 20px 20px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.solid-item .color-card {
  height: 100px;
  border-radius: 24px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.solid-item .solid-hex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2px 0 0 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.solid-item .solid-hex p {
  font-size: 15px;
  font-weight: 700;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.solid-item .solid-hex img {
  width: 24px;
  height: 24px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin: 0 12px 0 5px;
}

.solid-item .solid-hex img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.solid-item .solid-rgb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 0 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.solid-item .solid-rgb p {
  font-size: 15px;
  font-weight: 700;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.solid-item .solid-rgb img {
  width: 24px;
  height: 24px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin: 0 12px 0 5px;
}

.solid-item .solid-rgb img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.solid-item:hover {
  -webkit-filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
}

.solid-item:hover .color-card {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.gradient-item {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 24px 24px 20px 20px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.gradient-item .color-card {
  height: 100px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 24px;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.gradient-item .code-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2px 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.gradient-item .code-holder .color-codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.gradient-item .code-holder .color-codes .color-identifier {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.gradient-item .code-holder .color-codes .color-identifier:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.gradient-item .code-holder .color-codes .codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.gradient-item .code-holder .color-codes .codes .code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.gradient-item .code-holder .color-codes .codes .code p {
  font-size: 15px;
  font-weight: 700;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.gradient-item .code-holder .color-codes .codes .code img {
  width: 24px;
  height: 24px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 5px;
}

.gradient-item .code-holder .color-codes .codes .code img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.gradient-item:hover {
  -webkit-filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
}

.gradient-item:hover .color-card {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.swatch-item {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-bottom: 20px;
  border-radius: 24px;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
}

.swatch-item:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.swatch-item p {
  margin: 12px 0 0 16px;
  font-size: 20px;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.swatch-item .swatch-hex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.swatch-item .swatch-hex p {
  margin: 0 0 0 16px;
  font-size: 17px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.swatch-item .swatch-hex img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 5px;
}

.swatch-item .swatch-hex img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.swatch-item .swatch-rgb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2px 0 12px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.swatch-item .swatch-rgb p {
  margin: 0 0 0 16px;
  font-size: 17px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.swatch-item .swatch-rgb img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 5px;
}

.swatch-item .swatch-rgb img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.dual-item {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 24px 24px 20px 20px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.dual-item .color-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  border-radius: 24px;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.dual-item .color-cards .color-card {
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dual-item .color-cards:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.dual-item .color-codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 2px 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.dual-item .color-codes .codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.dual-item .color-codes .codes .code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.dual-item .color-codes .codes .code p {
  font-size: 15px;
  font-weight: 700;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.dual-item .color-codes .codes .code img {
  width: 24px;
  height: 24px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 5px;
}

.dual-item .color-codes .codes .code img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.dual-item:hover {
  -webkit-filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
}

.dual-item:hover .color-cards {
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.trial-item {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 24px 24px 20px 20px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.trial-item .color-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  border-radius: 24px;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.trial-item .color-cards .color-card {
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.trial-item .color-cards:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.trial-item .color-codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 2px 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.trial-item .color-codes .codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.trial-item .color-codes .codes .code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.trial-item .color-codes .codes .code p {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.trial-item .color-codes .codes .code img {
  width: 22px;
  height: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-left: 4px;
}

.trial-item .color-codes .codes .code img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.trial-item:hover {
  -webkit-filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
}

.trial-item:hover .color-cards {
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.quadral-item {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 24px 24px 20px 20px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));
}

.quadral-item .color-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  border-radius: 24px;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.quadral-item .color-cards .color-card {
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.quadral-item .color-cards:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.quadral-item .color-codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 4px 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.quadral-item .color-codes .codes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.quadral-item .color-codes .codes p {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.quadral-item .color-codes .codes p:hover {
  cursor: pointer;
}

.quadral-item .color-codes .codes p:nth-child(2) {
  margin-top: 4px;
}

.quadral-item:hover {
  -webkit-filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));
}

.quadral-item:hover .color-cards {
  -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
          filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}

.disabled {
  opacity: 0.5;
}

.disabled:hover {
  opacity: 0.5;
}

.toast {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 370px 8px 0;
  border-radius: 10px;
  padding: 10px 16px;
  font-size: medium;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-animation: var(--transition) ease 0s 1 toast-anim;
          animation: var(--transition) ease 0s 1 toast-anim;
  z-index: 2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: var(--background-color);
  background-color: var(--text-color);
}

.create-modal,
.url-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(var(--background-color-rgb), 0.2);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
}

.create-modal .modal-container,
.url-modal .modal-container {
  width: 360px;
  border-radius: 28px 28px 24px 24px;
  color: var(--text-color);
  background-color: var(--text-color);
}

.create-modal .modal-container .modal-content,
.url-modal .modal-container .modal-content {
  border-radius: 24px;
  padding: 16px 20px 20px 20px;
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
  background-color: var(--card-color);
}

.create-modal .modal-container .modal-content .modal-header,
.url-modal .modal-container .modal-content .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.create-modal .modal-container .modal-content .modal-header img,
.url-modal .modal-container .modal-content .modal-header img {
  width: 20px;
  height: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.create-modal .modal-container .modal-content .modal-header img:hover,
.url-modal .modal-container .modal-content .modal-header img:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  cursor: pointer;
}

.create-modal .modal-container .modal-content .modal-body,
.url-modal .modal-container .modal-content .modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.create-modal .modal-container .modal-content .modal-body ul,
.url-modal .modal-container .modal-content .modal-body ul {
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0 10px 0 20px;
}

.create-modal .modal-container .modal-content .modal-body ul li,
.url-modal .modal-container .modal-content .modal-body ul li {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 600;
}

.create-modal .modal-container .modal-content .modal-body input,
.url-modal .modal-container .modal-content .modal-body input {
  width: 56%;
  border: none;
  font-size: 22px;
  margin-top: 24px;
  text-align: center;
  color: var(--text-color);
  background-color: var(--card-color);
  padding: 10px 10px 5px 10px;
  border-bottom: 2px solid #808080;
}

.create-modal .modal-container .modal-content .modal-body input:focus,
.url-modal .modal-container .modal-content .modal-body input:focus {
  outline: none;
}

.create-modal .modal-container .modal-button,
.url-modal .modal-container .modal-button {
  padding: 8px 0 12px 0;
}

.create-modal .modal-container .modal-button p,
.url-modal .modal-container .modal-button p {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  font-size: large;
  font-weight: 900;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: var(--background-color);
}

.create-modal .modal-container .modal-button p:hover,
.url-modal .modal-container .modal-button p:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.settings-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(var(--background-color-rgb), 0.2);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
}

.settings-modal .modal-container {
  width: 512px;
  height: auto;
  border-radius: 24px;
  overflow: hidden;
  color: var(--text-color);
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}

.settings-modal .modal-container .settings-modal-header {
  padding: 12px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 24px 24px 0 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.settings-modal .modal-container .settings-modal-header img {
  width: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.settings-modal .modal-container .settings-modal-header img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.settings-modal .modal-container .settings-modal-body {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.settings-modal .modal-container .settings-modal-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.settings-modal .modal-container .settings-modal-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.settings-modal .modal-container .settings-modal-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: rgba(var(--text-color-rgb), 0.8);
}

.settings-modal .modal-container .settings-modal-body h3 {
  margin: 16px 0 0 24px;
}

.settings-modal .modal-container .settings-modal-body .settings-category {
  margin: 8px 24px 0 24px;
  padding: 16px;
  border-radius: 18px;
  background-color: var(--background-color);
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
}

.settings-modal .modal-container .settings-modal-body .theme {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.settings-modal .modal-container .settings-modal-body .theme .title-desc {
  width: 86%;
}

.settings-modal .modal-container .settings-modal-body .theme .title-desc p {
  margin-top: 4px;
  font-size: 15px;
}

.settings-modal .modal-container .settings-modal-body .palette .palette_seekbar_count {
  width: 100%;
  margin-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.settings-modal .modal-container .settings-modal-body .palette .palette_seekbar_count h3 {
  width: 30px;
  margin: 0 0 4px 12px;
}

.settings-modal .modal-container .settings-modal-body .palette .separator {
  height: 1px;
  width: 100%;
  margin-top: 16px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--base-color);
}

.settings-modal .modal-container .settings-modal-body .palette .info {
  margin-top: 8px;
  font-size: 14px;
}

.settings-modal .modal-container .settings-modal-body .feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.settings-modal .modal-container .settings-modal-body .feedback p {
  width: 86%;
}

.settings-modal .modal-container .settings-modal-body .feedback img {
  margin-right: 4px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 28px;
  height: 28px;
}

.settings-modal .modal-container .settings-modal-body .feedback img:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.settings-modal .modal-container .settings-modal-body .about-app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 18px 0 16px 24px;
}

.settings-modal .modal-container .settings-modal-body .about-app .app-logo {
  margin-bottom: 2px;
  width: 30px;
  height: 30px;
}

.settings-modal .modal-container .settings-modal-body .about-app .app-name {
  padding-bottom: 0;
  margin-left: 8px;
  font-weight: bolder;
  font-size: 18px;
}

.settings-modal .modal-container .settings-modal-body .about-app .app-version {
  margin: 0 0 2px 4px;
  font-weight: 800;
  font-size: 12px;
  opacity: 50%;
}

.extract-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(var(--background-color-rgb), 0.2);
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
}

.extract-modal .modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: var(--text-color);
}

.extract-modal .modal-container .extract-image {
  z-index: 1;
  width: 128px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 32px;
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.extract-modal .modal-container .extract-image:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.extract-modal .modal-container .swatch-extracts {
  width: 380px;
  height: 640px;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -64px;
  border-radius: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
  overflow-y: auto;
  scroll-behavior: smooth !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.extract-modal .modal-container .swatch-extracts::-webkit-scrollbar {
  display: none;
}

.extract-modal .modal-container .swatch-extracts .swatch-item {
  margin-bottom: 12px;
}

.extract-modal .modal-container .palette-extracts {
  width: 380px;
  height: 640px;
  margin-top: -64px;
  border-radius: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 12px 12px 24px 12px;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
  gap: 12px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  overflow-y: auto;
  scroll-behavior: smooth !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.extract-modal .modal-container .palette-extracts::-webkit-scrollbar {
  display: none;
}

.extract-modal .modal-container .modal-option {
  z-index: 1;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--card-color);
  -webkit-filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));
}

.extract-modal .modal-container .modal-option img {
  width: 18px;
}

.extract-modal .modal-container .modal-option:hover {
  cursor: pointer;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
}

.extract-modal .modal-container .close {
  margin: -20px 0 0 72px;
}

.extract-modal .modal-container .delete {
  margin: -40px 72px 0 0;
}

/* 1536x864 ***/
@media screen and (max-width: 1536px) and (max-height: 864px) {
  .container .menu {
    width: 84px;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(1) {
    height: 72%;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(2) {
    margin: 5px 18px;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(2) div p:nth-child(1) {
    font-size: 18px;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(2) img {
    width: 28px;
  }
  .container section .material-color .color-holder {
    width: 200px;
    grid-auto-rows: 68px;
  }
  .container section .create-color .solid-section .solid-body .solid-color {
    width: 50%;
  }
  .container section .create-color .solid-section .solid-body .solid-control {
    width: 50%;
  }
  .container section .create-color .solid-section .solid-body .solid-control .solid-attributes {
    margin: 24px;
  }
  .container section .create-color .solid-section .solid-body .solid-control .solid-attributes p {
    font-size: 17px;
  }
  .container section .create-color .solid-section .solid-body .solid-control .seekbars {
    margin-bottom: 56px;
  }
  .container section .create-color .solid-section .solid-body .solid-control .seekbars ul:nth-child(2) {
    margin-right: 10px;
  }
  .container section .create-color .gradient-section .gradient-body .gradient-color {
    width: 50%;
  }
  .container section .create-color .gradient-section .gradient-body .gradient-control {
    width: 50%;
  }
  .container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top p,
  .container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom p {
    font-size: 17px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-img-chooser {
    min-height: 200px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .logo-info img {
    width: 32px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .logo-info p {
    font-size: 15px;
    margin: 12px 0 0 8px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-extract-info p {
    font-size: medium;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-extract-info ul li {
    font-size: medium;
  }
  .container section .extract-color .palette-section .palette-body .palette-img-chooser {
    min-height: 200px;
  }
  .container section .extract-color .palette-section .palette-body .palette-img-chooser .logo-info img {
    width: 32px;
  }
  .container section .extract-color .palette-section .palette-body .palette-img-chooser .logo-info p {
    font-size: 15px;
    margin: 12px 0 0 8px;
  }
  .container section .extract-color .palette-section .palette-body .extracted-palette {
    width: 98%;
    gap: 18px;
  }
  .container section .extract-color .palette-section .palette-body .palette-extract-info p {
    font-size: medium;
  }
  .container section .extract-color .palette-section .palette-body .palette-extract-info ul li {
    font-size: medium;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card {
    margin: 16px 0;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card p {
    font-size: medium;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-hex img {
    width: 24px;
    height: 24px;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-choose-image {
    width: 40px;
    height: 40px;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-choose-image img {
    width: 20px;
    height: 20px;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-rgb img {
    width: 24px;
    height: 24px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container,
  .container section .harmonize-color .complement-container .split-body .colors-container,
  .container section .harmonize-color .complement-container .analogous-body .colors-container,
  .container section .harmonize-color .complement-container .triadic-body .colors-container,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container,
  .container section .harmonize-color .split-container .complement-body .colors-container,
  .container section .harmonize-color .split-container .split-body .colors-container,
  .container section .harmonize-color .split-container .analogous-body .colors-container,
  .container section .harmonize-color .split-container .triadic-body .colors-container,
  .container section .harmonize-color .split-container .tetradic-body .colors-container,
  .container section .harmonize-color .analogous-container .complement-body .colors-container,
  .container section .harmonize-color .analogous-container .split-body .colors-container,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container,
  .container section .harmonize-color .triadic-container .complement-body .colors-container,
  .container section .harmonize-color .triadic-container .split-body .colors-container,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container,
  .container section .harmonize-color .tetradic-container .split-body .colors-container,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container {
    margin-bottom: 8px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .colors,
  .container section .harmonize-color .complement-container .split-body .colors-container .colors,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .split-container .complement-body .colors-container .colors,
  .container section .harmonize-color .split-container .split-body .colors-container .colors,
  .container section .harmonize-color .split-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .split-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .split-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .split-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .colors {
    height: 100px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .codes,
  .container section .harmonize-color .complement-container .split-body .colors-container .codes,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .split-container .complement-body .colors-container .codes,
  .container section .harmonize-color .split-container .split-body .colors-container .codes,
  .container section .harmonize-color .split-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .split-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .split-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .split-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes {
    margin: 10px 0;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes div p {
    font-size: small;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .separator,
  .container section .harmonize-color .complement-container .split-body .colors-container .separator,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .split-container .complement-body .colors-container .separator,
  .container section .harmonize-color .split-container .split-body .colors-container .separator,
  .container section .harmonize-color .split-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .split-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .split-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .split-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .separator {
    margin-top: 12px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .info,
  .container section .harmonize-color .complement-container .split-body .colors-container .info,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .info,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .info,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .split-container .complement-body .colors-container .info,
  .container section .harmonize-color .split-container .split-body .colors-container .info,
  .container section .harmonize-color .split-container .analogous-body .colors-container .info,
  .container section .harmonize-color .split-container .triadic-body .colors-container .info,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .info,
  .container section .harmonize-color .analogous-container .split-body .colors-container .info,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .info,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .info,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .info,
  .container section .harmonize-color .triadic-container .split-body .colors-container .info,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .info,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .info,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .info {
    margin: 12px;
    font-size: 12px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode {
    margin: 6px 12px 12px 12px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode p {
    font-size: medium;
    font-weight: 700;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode input {
    margin: 2px 0 0 8px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-tetradic {
    width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-left: 20px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-tetradic img {
    width: 18px;
    height: 18px;
  }
  .container section .harmonize-color .complement-container .complement-body .color-wheel,
  .container section .harmonize-color .complement-container .split-body .color-wheel,
  .container section .harmonize-color .complement-container .analogous-body .color-wheel,
  .container section .harmonize-color .complement-container .triadic-body .color-wheel,
  .container section .harmonize-color .complement-container .tetradic-body .color-wheel,
  .container section .harmonize-color .split-container .complement-body .color-wheel,
  .container section .harmonize-color .split-container .split-body .color-wheel,
  .container section .harmonize-color .split-container .analogous-body .color-wheel,
  .container section .harmonize-color .split-container .triadic-body .color-wheel,
  .container section .harmonize-color .split-container .tetradic-body .color-wheel,
  .container section .harmonize-color .analogous-container .complement-body .color-wheel,
  .container section .harmonize-color .analogous-container .split-body .color-wheel,
  .container section .harmonize-color .analogous-container .analogous-body .color-wheel,
  .container section .harmonize-color .analogous-container .triadic-body .color-wheel,
  .container section .harmonize-color .analogous-container .tetradic-body .color-wheel,
  .container section .harmonize-color .triadic-container .complement-body .color-wheel,
  .container section .harmonize-color .triadic-container .split-body .color-wheel,
  .container section .harmonize-color .triadic-container .analogous-body .color-wheel,
  .container section .harmonize-color .triadic-container .triadic-body .color-wheel,
  .container section .harmonize-color .triadic-container .tetradic-body .color-wheel,
  .container section .harmonize-color .tetradic-container .complement-body .color-wheel,
  .container section .harmonize-color .tetradic-container .split-body .color-wheel,
  .container section .harmonize-color .tetradic-container .analogous-body .color-wheel,
  .container section .harmonize-color .tetradic-container .triadic-body .color-wheel,
  .container section .harmonize-color .tetradic-container .tetradic-body .color-wheel {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes {
    margin: 8px 0;
  }
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes div p {
    font-size: x-small;
  }
  .container section .saved-color .extraction-container .extraction-items {
    gap: 18px;
  }
  .swatch-item p {
    margin: 10px 0 0 14px;
    font-size: 18px;
  }
  .swatch-item .swatch-hex p,
  .swatch-item .swatch-rgb p {
    margin: 0 0 0 14px;
    font-size: 16px;
  }
  .swatch-item .swatch-hex img,
  .swatch-item .swatch-rgb img {
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
  .solid-item .color-card {
    height: 84px;
  }
  .solid-item .solid-hex {
    margin: 2px 0 0 0;
  }
  .solid-item .solid-hex p {
    margin-left: 10px;
    font-size: 13px;
  }
  .solid-item .solid-hex img {
    margin: 0 6px 0 0;
    width: 20px;
    height: 20px;
  }
  .solid-item .solid-rgb {
    margin: 0 0 2px 0;
  }
  .solid-item .solid-rgb p {
    margin-left: 10px;
    font-size: 13px;
  }
  .solid-item .solid-rgb img {
    margin: 0 6px 0 0;
    width: 20px;
    height: 20px;
  }
  .gradient-item .color-card {
    height: 84px;
  }
  .gradient-item .code-holder {
    margin: 2px 8px;
  }
  .gradient-item .code-holder .color-codes .color-identifier {
    width: 20px;
    height: 20px;
  }
  .gradient-item .code-holder .color-codes .codes {
    margin-left: 10px;
    margin-right: 10px;
  }
  .gradient-item .code-holder .color-codes .codes .code p {
    font-size: 13px;
  }
  .gradient-item .code-holder .color-codes .codes .code img {
    width: 20px;
    height: 20px;
  }
  .extract-modal .modal-container .extract-image {
    width: 100px;
    border-radius: 28px;
  }
  .extract-modal .modal-container .swatch-extracts {
    width: 360px;
    height: 560px;
    margin-top: -48px;
  }
  .extract-modal .modal-container .modal-option {
    width: 38px;
    height: 38px;
  }
  .extract-modal .modal-container .modal-option img {
    width: 16px;
  }
  .dual-item .color-cards,
  .trial-item .color-cards {
    height: 84px;
  }
  .dual-item .color-codes,
  .trial-item .color-codes {
    margin: 2px 10px;
  }
  .dual-item .color-codes .codes .code p,
  .trial-item .color-codes .codes .code p {
    font-size: 12px;
  }
  .dual-item .color-codes .codes .code img,
  .trial-item .color-codes .codes .code img {
    width: 20px;
    height: 20px;
  }
  .quadral-item .color-cards {
    height: 84px;
  }
  .quadral-item .color-codes {
    margin: 6px 10px;
  }
  .quadral-item .color-codes .codes {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .quadral-item .color-codes .codes p {
    font-size: 12px;
  }
}

/* 1440x900 ***/
@media screen and (max-width: 1440px) and (max-height: 900px) {
  .container .menu {
    width: 80px;
  }
  .container .menu div .logo {
    width: 40px;
    height: 40px;
  }
  .container .menu div ul li img {
    width: 28px;
    height: 28px;
  }
  .container .menu .settings {
    width: 28px;
    height: 28px;
  }
  .container section .header {
    height: 90px;
  }
  .container section .header div h1 {
    font-size: 30px;
  }
  .container section .header div p {
    font-size: 13px;
    margin-bottom: 6px;
  }
  .container section .header img {
    width: 28px;
    height: 28px;
  }
  .container section .material-color .color-shades {
    padding: 24px 20px;
  }
  .container section .material-color .color-shades .color-shade {
    margin: 14px;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(1) {
    height: 73%;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(2) {
    margin: 4px 16px;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(2) div p:nth-child(1) {
    font-size: 18px;
  }
  .container section .material-color .color-shades .color-shade div:nth-child(2) img {
    width: 24px;
  }
  .container section .material-color .color-holder {
    width: 120px;
    margin-bottom: 1px;
    grid-auto-rows: 72px;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .container section .create-color .solid-section .solid-header .solid-header-title img {
    width: 28px;
    height: 28px;
  }
  .container section .create-color .solid-section .solid-header .solid-header-title p {
    font-size: 19px;
  }
  .container section .create-color .solid-section .solid-header .solid-alpha-control p {
    font-size: 19px;
  }
  .container section .create-color .solid-section .solid-header .solid-alpha-control input {
    margin-top: 4px;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .container section .create-color .solid-section .solid-body .solid-color {
    width: 45%;
  }
  .container section .create-color .solid-section .solid-body .solid-control {
    width: 55%;
  }
  .container section .create-color .solid-section .solid-body .solid-control .solid-attributes {
    margin: 24px;
  }
  .container section .create-color .solid-section .solid-body .solid-control .solid-attributes p {
    font-size: 17px;
  }
  .container section .create-color .solid-section .solid-body .solid-control .solid-attributes img {
    width: 24px;
    height: 24px;
  }
  .container section .create-color .solid-section .solid-body .solid-control .seekbars {
    margin-bottom: 56px;
  }
  .container section .create-color .solid-section .solid-body .solid-control .seekbars ul:nth-child(2) {
    margin-right: 6px;
  }
  .container section .create-color .gradient-section .gradient-header .gradient-header-title img {
    width: 28px;
    height: 28px;
  }
  .container section .create-color .gradient-section .gradient-header .gradient-header-title p {
    font-size: 19px;
  }
  .container section .create-color .gradient-section .gradient-header .gradient-alpha-control p {
    font-size: 19px;
  }
  .container section .create-color .gradient-section .gradient-header .gradient-alpha-control input {
    margin-top: 4px;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .container section .create-color .gradient-section .gradient-body .gradient-color {
    width: 45%;
  }
  .container section .create-color .gradient-section .gradient-body .gradient-control {
    width: 55%;
  }
  .container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top p,
  .container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom p {
    font-size: 17px;
  }
  .container section .create-color .gradient-section .gradient-body .gradient-control .gradient-top img,
  .container section .create-color .gradient-section .gradient-body .gradient-control .gradient-bottom img {
    width: 24px;
    height: 24px;
  }
  .container section .extract-color .swatch-section .swatch-header .swatch-header-title p {
    font-size: 19px;
  }
  .container section .extract-color .swatch-section .swatch-header .swatch-header-title img {
    width: 28px;
    height: 28px;
  }
  .container section .extract-color .swatch-section .swatch-header .swatch-reset p {
    font-size: 19px;
  }
  .container section .extract-color .swatch-section .swatch-header .swatch-reset img {
    width: 24px;
    height: 24px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-img-chooser {
    min-height: 180px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .logo-info img {
    width: 32px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-img-chooser .logo-info p {
    font-size: 15px;
    margin: 12px 0 0 8px;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-extract-info p {
    font-size: medium;
  }
  .container section .extract-color .swatch-section .swatch-body .swatch-extract-info ul li {
    font-size: medium;
  }
  .container section .extract-color .palette-section .palette-header .palette-header-title p {
    font-size: 19px;
  }
  .container section .extract-color .palette-section .palette-header .palette-header-title img {
    width: 28px;
    height: 28px;
  }
  .container section .extract-color .palette-section .palette-header .palette-reset p {
    font-size: 19px;
  }
  .container section .extract-color .palette-section .palette-header .palette-reset img {
    width: 24px;
    height: 24px;
  }
  .container section .extract-color .palette-section .palette-body .palette-img-chooser {
    min-height: 180px;
  }
  .container section .extract-color .palette-section .palette-body .palette-img-chooser .logo-info img {
    width: 32px;
  }
  .container section .extract-color .palette-section .palette-body .palette-img-chooser .logo-info p {
    font-size: 15px;
    margin: 12px 0 0 8px;
  }
  .container section .extract-color .palette-section .palette-body .extracted-palette {
    width: 95%;
    gap: 20px;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .container section .extract-color .palette-section .palette-body .palette-extract-info p {
    font-size: medium;
  }
  .container section .extract-color .palette-section .palette-body .palette-extract-info ul li {
    font-size: medium;
  }
  .container section .extract-color .manual-section .manual-header .manual-header-title p {
    font-size: 19px;
  }
  .container section .extract-color .manual-section .manual-header .manual-header-title img {
    width: 28px;
    height: 28px;
  }
  .container section .extract-color .manual-section .manual-header .manual-reset p {
    font-size: 19px;
  }
  .container section .extract-color .manual-section .manual-header .manual-reset img {
    width: 24px;
    height: 24px;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card {
    margin: 16px 0;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card p {
    font-size: medium;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-hex img {
    width: 24px;
    height: 24px;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-choose-image {
    width: 40px;
    height: 40px;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-choose-image img {
    width: 20px;
    height: 20px;
  }
  .container section .extract-color .manual-section .manual-body .manual-img-background .manual-color-card .manual-color-rgb img {
    width: 24px;
    height: 24px;
  }
  .container section .harmonize-color .complement-container .complement-header .complement-header-title p,
  .container section .harmonize-color .complement-container .complement-header .split-header-title p,
  .container section .harmonize-color .complement-container .complement-header .analogous-header-title p,
  .container section .harmonize-color .complement-container .complement-header .triadic-header-title p,
  .container section .harmonize-color .complement-container .complement-header .tetradic-header-title p,
  .container section .harmonize-color .complement-container .split-header .complement-header-title p,
  .container section .harmonize-color .complement-container .split-header .split-header-title p,
  .container section .harmonize-color .complement-container .split-header .analogous-header-title p,
  .container section .harmonize-color .complement-container .split-header .triadic-header-title p,
  .container section .harmonize-color .complement-container .split-header .tetradic-header-title p,
  .container section .harmonize-color .complement-container .analogous-header .complement-header-title p,
  .container section .harmonize-color .complement-container .analogous-header .split-header-title p,
  .container section .harmonize-color .complement-container .analogous-header .analogous-header-title p,
  .container section .harmonize-color .complement-container .analogous-header .triadic-header-title p,
  .container section .harmonize-color .complement-container .analogous-header .tetradic-header-title p,
  .container section .harmonize-color .complement-container .triadic-header .complement-header-title p,
  .container section .harmonize-color .complement-container .triadic-header .split-header-title p,
  .container section .harmonize-color .complement-container .triadic-header .analogous-header-title p,
  .container section .harmonize-color .complement-container .triadic-header .triadic-header-title p,
  .container section .harmonize-color .complement-container .triadic-header .tetradic-header-title p,
  .container section .harmonize-color .complement-container .tetradic-header .complement-header-title p,
  .container section .harmonize-color .complement-container .tetradic-header .split-header-title p,
  .container section .harmonize-color .complement-container .tetradic-header .analogous-header-title p,
  .container section .harmonize-color .complement-container .tetradic-header .triadic-header-title p,
  .container section .harmonize-color .complement-container .tetradic-header .tetradic-header-title p,
  .container section .harmonize-color .split-container .complement-header .complement-header-title p,
  .container section .harmonize-color .split-container .complement-header .split-header-title p,
  .container section .harmonize-color .split-container .complement-header .analogous-header-title p,
  .container section .harmonize-color .split-container .complement-header .triadic-header-title p,
  .container section .harmonize-color .split-container .complement-header .tetradic-header-title p,
  .container section .harmonize-color .split-container .split-header .complement-header-title p,
  .container section .harmonize-color .split-container .split-header .split-header-title p,
  .container section .harmonize-color .split-container .split-header .analogous-header-title p,
  .container section .harmonize-color .split-container .split-header .triadic-header-title p,
  .container section .harmonize-color .split-container .split-header .tetradic-header-title p,
  .container section .harmonize-color .split-container .analogous-header .complement-header-title p,
  .container section .harmonize-color .split-container .analogous-header .split-header-title p,
  .container section .harmonize-color .split-container .analogous-header .analogous-header-title p,
  .container section .harmonize-color .split-container .analogous-header .triadic-header-title p,
  .container section .harmonize-color .split-container .analogous-header .tetradic-header-title p,
  .container section .harmonize-color .split-container .triadic-header .complement-header-title p,
  .container section .harmonize-color .split-container .triadic-header .split-header-title p,
  .container section .harmonize-color .split-container .triadic-header .analogous-header-title p,
  .container section .harmonize-color .split-container .triadic-header .triadic-header-title p,
  .container section .harmonize-color .split-container .triadic-header .tetradic-header-title p,
  .container section .harmonize-color .split-container .tetradic-header .complement-header-title p,
  .container section .harmonize-color .split-container .tetradic-header .split-header-title p,
  .container section .harmonize-color .split-container .tetradic-header .analogous-header-title p,
  .container section .harmonize-color .split-container .tetradic-header .triadic-header-title p,
  .container section .harmonize-color .split-container .tetradic-header .tetradic-header-title p,
  .container section .harmonize-color .analogous-container .complement-header .complement-header-title p,
  .container section .harmonize-color .analogous-container .complement-header .split-header-title p,
  .container section .harmonize-color .analogous-container .complement-header .analogous-header-title p,
  .container section .harmonize-color .analogous-container .complement-header .triadic-header-title p,
  .container section .harmonize-color .analogous-container .complement-header .tetradic-header-title p,
  .container section .harmonize-color .analogous-container .split-header .complement-header-title p,
  .container section .harmonize-color .analogous-container .split-header .split-header-title p,
  .container section .harmonize-color .analogous-container .split-header .analogous-header-title p,
  .container section .harmonize-color .analogous-container .split-header .triadic-header-title p,
  .container section .harmonize-color .analogous-container .split-header .tetradic-header-title p,
  .container section .harmonize-color .analogous-container .analogous-header .complement-header-title p,
  .container section .harmonize-color .analogous-container .analogous-header .split-header-title p,
  .container section .harmonize-color .analogous-container .analogous-header .analogous-header-title p,
  .container section .harmonize-color .analogous-container .analogous-header .triadic-header-title p,
  .container section .harmonize-color .analogous-container .analogous-header .tetradic-header-title p,
  .container section .harmonize-color .analogous-container .triadic-header .complement-header-title p,
  .container section .harmonize-color .analogous-container .triadic-header .split-header-title p,
  .container section .harmonize-color .analogous-container .triadic-header .analogous-header-title p,
  .container section .harmonize-color .analogous-container .triadic-header .triadic-header-title p,
  .container section .harmonize-color .analogous-container .triadic-header .tetradic-header-title p,
  .container section .harmonize-color .analogous-container .tetradic-header .complement-header-title p,
  .container section .harmonize-color .analogous-container .tetradic-header .split-header-title p,
  .container section .harmonize-color .analogous-container .tetradic-header .analogous-header-title p,
  .container section .harmonize-color .analogous-container .tetradic-header .triadic-header-title p,
  .container section .harmonize-color .analogous-container .tetradic-header .tetradic-header-title p,
  .container section .harmonize-color .triadic-container .complement-header .complement-header-title p,
  .container section .harmonize-color .triadic-container .complement-header .split-header-title p,
  .container section .harmonize-color .triadic-container .complement-header .analogous-header-title p,
  .container section .harmonize-color .triadic-container .complement-header .triadic-header-title p,
  .container section .harmonize-color .triadic-container .complement-header .tetradic-header-title p,
  .container section .harmonize-color .triadic-container .split-header .complement-header-title p,
  .container section .harmonize-color .triadic-container .split-header .split-header-title p,
  .container section .harmonize-color .triadic-container .split-header .analogous-header-title p,
  .container section .harmonize-color .triadic-container .split-header .triadic-header-title p,
  .container section .harmonize-color .triadic-container .split-header .tetradic-header-title p,
  .container section .harmonize-color .triadic-container .analogous-header .complement-header-title p,
  .container section .harmonize-color .triadic-container .analogous-header .split-header-title p,
  .container section .harmonize-color .triadic-container .analogous-header .analogous-header-title p,
  .container section .harmonize-color .triadic-container .analogous-header .triadic-header-title p,
  .container section .harmonize-color .triadic-container .analogous-header .tetradic-header-title p,
  .container section .harmonize-color .triadic-container .triadic-header .complement-header-title p,
  .container section .harmonize-color .triadic-container .triadic-header .split-header-title p,
  .container section .harmonize-color .triadic-container .triadic-header .analogous-header-title p,
  .container section .harmonize-color .triadic-container .triadic-header .triadic-header-title p,
  .container section .harmonize-color .triadic-container .triadic-header .tetradic-header-title p,
  .container section .harmonize-color .triadic-container .tetradic-header .complement-header-title p,
  .container section .harmonize-color .triadic-container .tetradic-header .split-header-title p,
  .container section .harmonize-color .triadic-container .tetradic-header .analogous-header-title p,
  .container section .harmonize-color .triadic-container .tetradic-header .triadic-header-title p,
  .container section .harmonize-color .triadic-container .tetradic-header .tetradic-header-title p,
  .container section .harmonize-color .tetradic-container .complement-header .complement-header-title p,
  .container section .harmonize-color .tetradic-container .complement-header .split-header-title p,
  .container section .harmonize-color .tetradic-container .complement-header .analogous-header-title p,
  .container section .harmonize-color .tetradic-container .complement-header .triadic-header-title p,
  .container section .harmonize-color .tetradic-container .complement-header .tetradic-header-title p,
  .container section .harmonize-color .tetradic-container .split-header .complement-header-title p,
  .container section .harmonize-color .tetradic-container .split-header .split-header-title p,
  .container section .harmonize-color .tetradic-container .split-header .analogous-header-title p,
  .container section .harmonize-color .tetradic-container .split-header .triadic-header-title p,
  .container section .harmonize-color .tetradic-container .split-header .tetradic-header-title p,
  .container section .harmonize-color .tetradic-container .analogous-header .complement-header-title p,
  .container section .harmonize-color .tetradic-container .analogous-header .split-header-title p,
  .container section .harmonize-color .tetradic-container .analogous-header .analogous-header-title p,
  .container section .harmonize-color .tetradic-container .analogous-header .triadic-header-title p,
  .container section .harmonize-color .tetradic-container .analogous-header .tetradic-header-title p,
  .container section .harmonize-color .tetradic-container .triadic-header .complement-header-title p,
  .container section .harmonize-color .tetradic-container .triadic-header .split-header-title p,
  .container section .harmonize-color .tetradic-container .triadic-header .analogous-header-title p,
  .container section .harmonize-color .tetradic-container .triadic-header .triadic-header-title p,
  .container section .harmonize-color .tetradic-container .triadic-header .tetradic-header-title p,
  .container section .harmonize-color .tetradic-container .tetradic-header .complement-header-title p,
  .container section .harmonize-color .tetradic-container .tetradic-header .split-header-title p,
  .container section .harmonize-color .tetradic-container .tetradic-header .analogous-header-title p,
  .container section .harmonize-color .tetradic-container .tetradic-header .triadic-header-title p,
  .container section .harmonize-color .tetradic-container .tetradic-header .tetradic-header-title p {
    font-size: 19px;
  }
  .container section .harmonize-color .complement-container .complement-header .complement-header-title img,
  .container section .harmonize-color .complement-container .complement-header .split-header-title img,
  .container section .harmonize-color .complement-container .complement-header .analogous-header-title img,
  .container section .harmonize-color .complement-container .complement-header .triadic-header-title img,
  .container section .harmonize-color .complement-container .complement-header .tetradic-header-title img,
  .container section .harmonize-color .complement-container .split-header .complement-header-title img,
  .container section .harmonize-color .complement-container .split-header .split-header-title img,
  .container section .harmonize-color .complement-container .split-header .analogous-header-title img,
  .container section .harmonize-color .complement-container .split-header .triadic-header-title img,
  .container section .harmonize-color .complement-container .split-header .tetradic-header-title img,
  .container section .harmonize-color .complement-container .analogous-header .complement-header-title img,
  .container section .harmonize-color .complement-container .analogous-header .split-header-title img,
  .container section .harmonize-color .complement-container .analogous-header .analogous-header-title img,
  .container section .harmonize-color .complement-container .analogous-header .triadic-header-title img,
  .container section .harmonize-color .complement-container .analogous-header .tetradic-header-title img,
  .container section .harmonize-color .complement-container .triadic-header .complement-header-title img,
  .container section .harmonize-color .complement-container .triadic-header .split-header-title img,
  .container section .harmonize-color .complement-container .triadic-header .analogous-header-title img,
  .container section .harmonize-color .complement-container .triadic-header .triadic-header-title img,
  .container section .harmonize-color .complement-container .triadic-header .tetradic-header-title img,
  .container section .harmonize-color .complement-container .tetradic-header .complement-header-title img,
  .container section .harmonize-color .complement-container .tetradic-header .split-header-title img,
  .container section .harmonize-color .complement-container .tetradic-header .analogous-header-title img,
  .container section .harmonize-color .complement-container .tetradic-header .triadic-header-title img,
  .container section .harmonize-color .complement-container .tetradic-header .tetradic-header-title img,
  .container section .harmonize-color .split-container .complement-header .complement-header-title img,
  .container section .harmonize-color .split-container .complement-header .split-header-title img,
  .container section .harmonize-color .split-container .complement-header .analogous-header-title img,
  .container section .harmonize-color .split-container .complement-header .triadic-header-title img,
  .container section .harmonize-color .split-container .complement-header .tetradic-header-title img,
  .container section .harmonize-color .split-container .split-header .complement-header-title img,
  .container section .harmonize-color .split-container .split-header .split-header-title img,
  .container section .harmonize-color .split-container .split-header .analogous-header-title img,
  .container section .harmonize-color .split-container .split-header .triadic-header-title img,
  .container section .harmonize-color .split-container .split-header .tetradic-header-title img,
  .container section .harmonize-color .split-container .analogous-header .complement-header-title img,
  .container section .harmonize-color .split-container .analogous-header .split-header-title img,
  .container section .harmonize-color .split-container .analogous-header .analogous-header-title img,
  .container section .harmonize-color .split-container .analogous-header .triadic-header-title img,
  .container section .harmonize-color .split-container .analogous-header .tetradic-header-title img,
  .container section .harmonize-color .split-container .triadic-header .complement-header-title img,
  .container section .harmonize-color .split-container .triadic-header .split-header-title img,
  .container section .harmonize-color .split-container .triadic-header .analogous-header-title img,
  .container section .harmonize-color .split-container .triadic-header .triadic-header-title img,
  .container section .harmonize-color .split-container .triadic-header .tetradic-header-title img,
  .container section .harmonize-color .split-container .tetradic-header .complement-header-title img,
  .container section .harmonize-color .split-container .tetradic-header .split-header-title img,
  .container section .harmonize-color .split-container .tetradic-header .analogous-header-title img,
  .container section .harmonize-color .split-container .tetradic-header .triadic-header-title img,
  .container section .harmonize-color .split-container .tetradic-header .tetradic-header-title img,
  .container section .harmonize-color .analogous-container .complement-header .complement-header-title img,
  .container section .harmonize-color .analogous-container .complement-header .split-header-title img,
  .container section .harmonize-color .analogous-container .complement-header .analogous-header-title img,
  .container section .harmonize-color .analogous-container .complement-header .triadic-header-title img,
  .container section .harmonize-color .analogous-container .complement-header .tetradic-header-title img,
  .container section .harmonize-color .analogous-container .split-header .complement-header-title img,
  .container section .harmonize-color .analogous-container .split-header .split-header-title img,
  .container section .harmonize-color .analogous-container .split-header .analogous-header-title img,
  .container section .harmonize-color .analogous-container .split-header .triadic-header-title img,
  .container section .harmonize-color .analogous-container .split-header .tetradic-header-title img,
  .container section .harmonize-color .analogous-container .analogous-header .complement-header-title img,
  .container section .harmonize-color .analogous-container .analogous-header .split-header-title img,
  .container section .harmonize-color .analogous-container .analogous-header .analogous-header-title img,
  .container section .harmonize-color .analogous-container .analogous-header .triadic-header-title img,
  .container section .harmonize-color .analogous-container .analogous-header .tetradic-header-title img,
  .container section .harmonize-color .analogous-container .triadic-header .complement-header-title img,
  .container section .harmonize-color .analogous-container .triadic-header .split-header-title img,
  .container section .harmonize-color .analogous-container .triadic-header .analogous-header-title img,
  .container section .harmonize-color .analogous-container .triadic-header .triadic-header-title img,
  .container section .harmonize-color .analogous-container .triadic-header .tetradic-header-title img,
  .container section .harmonize-color .analogous-container .tetradic-header .complement-header-title img,
  .container section .harmonize-color .analogous-container .tetradic-header .split-header-title img,
  .container section .harmonize-color .analogous-container .tetradic-header .analogous-header-title img,
  .container section .harmonize-color .analogous-container .tetradic-header .triadic-header-title img,
  .container section .harmonize-color .analogous-container .tetradic-header .tetradic-header-title img,
  .container section .harmonize-color .triadic-container .complement-header .complement-header-title img,
  .container section .harmonize-color .triadic-container .complement-header .split-header-title img,
  .container section .harmonize-color .triadic-container .complement-header .analogous-header-title img,
  .container section .harmonize-color .triadic-container .complement-header .triadic-header-title img,
  .container section .harmonize-color .triadic-container .complement-header .tetradic-header-title img,
  .container section .harmonize-color .triadic-container .split-header .complement-header-title img,
  .container section .harmonize-color .triadic-container .split-header .split-header-title img,
  .container section .harmonize-color .triadic-container .split-header .analogous-header-title img,
  .container section .harmonize-color .triadic-container .split-header .triadic-header-title img,
  .container section .harmonize-color .triadic-container .split-header .tetradic-header-title img,
  .container section .harmonize-color .triadic-container .analogous-header .complement-header-title img,
  .container section .harmonize-color .triadic-container .analogous-header .split-header-title img,
  .container section .harmonize-color .triadic-container .analogous-header .analogous-header-title img,
  .container section .harmonize-color .triadic-container .analogous-header .triadic-header-title img,
  .container section .harmonize-color .triadic-container .analogous-header .tetradic-header-title img,
  .container section .harmonize-color .triadic-container .triadic-header .complement-header-title img,
  .container section .harmonize-color .triadic-container .triadic-header .split-header-title img,
  .container section .harmonize-color .triadic-container .triadic-header .analogous-header-title img,
  .container section .harmonize-color .triadic-container .triadic-header .triadic-header-title img,
  .container section .harmonize-color .triadic-container .triadic-header .tetradic-header-title img,
  .container section .harmonize-color .triadic-container .tetradic-header .complement-header-title img,
  .container section .harmonize-color .triadic-container .tetradic-header .split-header-title img,
  .container section .harmonize-color .triadic-container .tetradic-header .analogous-header-title img,
  .container section .harmonize-color .triadic-container .tetradic-header .triadic-header-title img,
  .container section .harmonize-color .triadic-container .tetradic-header .tetradic-header-title img,
  .container section .harmonize-color .tetradic-container .complement-header .complement-header-title img,
  .container section .harmonize-color .tetradic-container .complement-header .split-header-title img,
  .container section .harmonize-color .tetradic-container .complement-header .analogous-header-title img,
  .container section .harmonize-color .tetradic-container .complement-header .triadic-header-title img,
  .container section .harmonize-color .tetradic-container .complement-header .tetradic-header-title img,
  .container section .harmonize-color .tetradic-container .split-header .complement-header-title img,
  .container section .harmonize-color .tetradic-container .split-header .split-header-title img,
  .container section .harmonize-color .tetradic-container .split-header .analogous-header-title img,
  .container section .harmonize-color .tetradic-container .split-header .triadic-header-title img,
  .container section .harmonize-color .tetradic-container .split-header .tetradic-header-title img,
  .container section .harmonize-color .tetradic-container .analogous-header .complement-header-title img,
  .container section .harmonize-color .tetradic-container .analogous-header .split-header-title img,
  .container section .harmonize-color .tetradic-container .analogous-header .analogous-header-title img,
  .container section .harmonize-color .tetradic-container .analogous-header .triadic-header-title img,
  .container section .harmonize-color .tetradic-container .analogous-header .tetradic-header-title img,
  .container section .harmonize-color .tetradic-container .triadic-header .complement-header-title img,
  .container section .harmonize-color .tetradic-container .triadic-header .split-header-title img,
  .container section .harmonize-color .tetradic-container .triadic-header .analogous-header-title img,
  .container section .harmonize-color .tetradic-container .triadic-header .triadic-header-title img,
  .container section .harmonize-color .tetradic-container .triadic-header .tetradic-header-title img,
  .container section .harmonize-color .tetradic-container .tetradic-header .complement-header-title img,
  .container section .harmonize-color .tetradic-container .tetradic-header .split-header-title img,
  .container section .harmonize-color .tetradic-container .tetradic-header .analogous-header-title img,
  .container section .harmonize-color .tetradic-container .tetradic-header .triadic-header-title img,
  .container section .harmonize-color .tetradic-container .tetradic-header .tetradic-header-title img {
    width: 28px;
    height: 28px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container,
  .container section .harmonize-color .complement-container .split-body .colors-container,
  .container section .harmonize-color .complement-container .analogous-body .colors-container,
  .container section .harmonize-color .complement-container .triadic-body .colors-container,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container,
  .container section .harmonize-color .split-container .complement-body .colors-container,
  .container section .harmonize-color .split-container .split-body .colors-container,
  .container section .harmonize-color .split-container .analogous-body .colors-container,
  .container section .harmonize-color .split-container .triadic-body .colors-container,
  .container section .harmonize-color .split-container .tetradic-body .colors-container,
  .container section .harmonize-color .analogous-container .complement-body .colors-container,
  .container section .harmonize-color .analogous-container .split-body .colors-container,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container,
  .container section .harmonize-color .triadic-container .complement-body .colors-container,
  .container section .harmonize-color .triadic-container .split-body .colors-container,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container,
  .container section .harmonize-color .tetradic-container .split-body .colors-container,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container {
    margin-bottom: 8px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .colors,
  .container section .harmonize-color .complement-container .split-body .colors-container .colors,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .split-container .complement-body .colors-container .colors,
  .container section .harmonize-color .split-container .split-body .colors-container .colors,
  .container section .harmonize-color .split-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .split-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .split-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .split-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .colors,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .colors {
    height: 100px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .codes,
  .container section .harmonize-color .complement-container .split-body .colors-container .codes,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .split-container .complement-body .colors-container .codes,
  .container section .harmonize-color .split-container .split-body .colors-container .codes,
  .container section .harmonize-color .split-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .split-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .split-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .split-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .codes,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes {
    margin: 10px 0;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .codes div p,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes div p {
    font-size: small;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .separator,
  .container section .harmonize-color .complement-container .split-body .colors-container .separator,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .split-container .complement-body .colors-container .separator,
  .container section .harmonize-color .split-container .split-body .colors-container .separator,
  .container section .harmonize-color .split-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .split-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .split-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .split-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .separator,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .separator {
    margin-top: 12px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .info,
  .container section .harmonize-color .complement-container .split-body .colors-container .info,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .info,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .info,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .split-container .complement-body .colors-container .info,
  .container section .harmonize-color .split-container .split-body .colors-container .info,
  .container section .harmonize-color .split-container .analogous-body .colors-container .info,
  .container section .harmonize-color .split-container .triadic-body .colors-container .info,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .info,
  .container section .harmonize-color .analogous-container .split-body .colors-container .info,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .info,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .info,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .info,
  .container section .harmonize-color .triadic-container .split-body .colors-container .info,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .info,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .info,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .info,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .info {
    margin: 12px;
    font-size: 12px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode {
    margin: 6px 12px 12px 12px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode p,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode p {
    font-size: medium;
    font-weight: 700;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode input,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode input {
    margin: 2px 0 0 8px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-tetradic,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-complement,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-split,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-analogous,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-triadic,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-tetradic {
    width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-left: 20px;
  }
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .complement-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .split-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .analogous-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .triadic-container .tetradic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .complement-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .split-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .analogous-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .triadic-body .colors-container .invert-mode .save-tetradic img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-complement img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-split img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-analogous img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-triadic img,
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .invert-mode .save-tetradic img {
    width: 18px;
    height: 18px;
  }
  .container section .harmonize-color .complement-container .complement-body .color-wheel,
  .container section .harmonize-color .complement-container .split-body .color-wheel,
  .container section .harmonize-color .complement-container .analogous-body .color-wheel,
  .container section .harmonize-color .complement-container .triadic-body .color-wheel,
  .container section .harmonize-color .complement-container .tetradic-body .color-wheel,
  .container section .harmonize-color .split-container .complement-body .color-wheel,
  .container section .harmonize-color .split-container .split-body .color-wheel,
  .container section .harmonize-color .split-container .analogous-body .color-wheel,
  .container section .harmonize-color .split-container .triadic-body .color-wheel,
  .container section .harmonize-color .split-container .tetradic-body .color-wheel,
  .container section .harmonize-color .analogous-container .complement-body .color-wheel,
  .container section .harmonize-color .analogous-container .split-body .color-wheel,
  .container section .harmonize-color .analogous-container .analogous-body .color-wheel,
  .container section .harmonize-color .analogous-container .triadic-body .color-wheel,
  .container section .harmonize-color .analogous-container .tetradic-body .color-wheel,
  .container section .harmonize-color .triadic-container .complement-body .color-wheel,
  .container section .harmonize-color .triadic-container .split-body .color-wheel,
  .container section .harmonize-color .triadic-container .analogous-body .color-wheel,
  .container section .harmonize-color .triadic-container .triadic-body .color-wheel,
  .container section .harmonize-color .triadic-container .tetradic-body .color-wheel,
  .container section .harmonize-color .tetradic-container .complement-body .color-wheel,
  .container section .harmonize-color .tetradic-container .split-body .color-wheel,
  .container section .harmonize-color .tetradic-container .analogous-body .color-wheel,
  .container section .harmonize-color .tetradic-container .triadic-body .color-wheel,
  .container section .harmonize-color .tetradic-container .tetradic-body .color-wheel {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes {
    margin: 8px 0;
  }
  .container section .harmonize-color .tetradic-container .tetradic-body .colors-container .codes div p {
    font-size: 11px;
  }
  .container section .saved-color .solgrad-container .solgrad-header .solgrad-header-title p,
  .container section .saved-color .solgrad-container .solgrad-header .extraction-header-title p,
  .container section .saved-color .solgrad-container .solgrad-header .harmony-header-title p,
  .container section .saved-color .solgrad-container .solgrad-header .preset-header-title p,
  .container section .saved-color .solgrad-container .extraction-header .solgrad-header-title p,
  .container section .saved-color .solgrad-container .extraction-header .extraction-header-title p,
  .container section .saved-color .solgrad-container .extraction-header .harmony-header-title p,
  .container section .saved-color .solgrad-container .extraction-header .preset-header-title p,
  .container section .saved-color .solgrad-container .harmony-header .solgrad-header-title p,
  .container section .saved-color .solgrad-container .harmony-header .extraction-header-title p,
  .container section .saved-color .solgrad-container .harmony-header .harmony-header-title p,
  .container section .saved-color .solgrad-container .harmony-header .preset-header-title p,
  .container section .saved-color .solgrad-container .preset-header .solgrad-header-title p,
  .container section .saved-color .solgrad-container .preset-header .extraction-header-title p,
  .container section .saved-color .solgrad-container .preset-header .harmony-header-title p,
  .container section .saved-color .solgrad-container .preset-header .preset-header-title p,
  .container section .saved-color .extraction-container .solgrad-header .solgrad-header-title p,
  .container section .saved-color .extraction-container .solgrad-header .extraction-header-title p,
  .container section .saved-color .extraction-container .solgrad-header .harmony-header-title p,
  .container section .saved-color .extraction-container .solgrad-header .preset-header-title p,
  .container section .saved-color .extraction-container .extraction-header .solgrad-header-title p,
  .container section .saved-color .extraction-container .extraction-header .extraction-header-title p,
  .container section .saved-color .extraction-container .extraction-header .harmony-header-title p,
  .container section .saved-color .extraction-container .extraction-header .preset-header-title p,
  .container section .saved-color .extraction-container .harmony-header .solgrad-header-title p,
  .container section .saved-color .extraction-container .harmony-header .extraction-header-title p,
  .container section .saved-color .extraction-container .harmony-header .harmony-header-title p,
  .container section .saved-color .extraction-container .harmony-header .preset-header-title p,
  .container section .saved-color .extraction-container .preset-header .solgrad-header-title p,
  .container section .saved-color .extraction-container .preset-header .extraction-header-title p,
  .container section .saved-color .extraction-container .preset-header .harmony-header-title p,
  .container section .saved-color .extraction-container .preset-header .preset-header-title p,
  .container section .saved-color .harmony-container .solgrad-header .solgrad-header-title p,
  .container section .saved-color .harmony-container .solgrad-header .extraction-header-title p,
  .container section .saved-color .harmony-container .solgrad-header .harmony-header-title p,
  .container section .saved-color .harmony-container .solgrad-header .preset-header-title p,
  .container section .saved-color .harmony-container .extraction-header .solgrad-header-title p,
  .container section .saved-color .harmony-container .extraction-header .extraction-header-title p,
  .container section .saved-color .harmony-container .extraction-header .harmony-header-title p,
  .container section .saved-color .harmony-container .extraction-header .preset-header-title p,
  .container section .saved-color .harmony-container .harmony-header .solgrad-header-title p,
  .container section .saved-color .harmony-container .harmony-header .extraction-header-title p,
  .container section .saved-color .harmony-container .harmony-header .harmony-header-title p,
  .container section .saved-color .harmony-container .harmony-header .preset-header-title p,
  .container section .saved-color .harmony-container .preset-header .solgrad-header-title p,
  .container section .saved-color .harmony-container .preset-header .extraction-header-title p,
  .container section .saved-color .harmony-container .preset-header .harmony-header-title p,
  .container section .saved-color .harmony-container .preset-header .preset-header-title p,
  .container section .saved-color .preset-container .solgrad-header .solgrad-header-title p,
  .container section .saved-color .preset-container .solgrad-header .extraction-header-title p,
  .container section .saved-color .preset-container .solgrad-header .harmony-header-title p,
  .container section .saved-color .preset-container .solgrad-header .preset-header-title p,
  .container section .saved-color .preset-container .extraction-header .solgrad-header-title p,
  .container section .saved-color .preset-container .extraction-header .extraction-header-title p,
  .container section .saved-color .preset-container .extraction-header .harmony-header-title p,
  .container section .saved-color .preset-container .extraction-header .preset-header-title p,
  .container section .saved-color .preset-container .harmony-header .solgrad-header-title p,
  .container section .saved-color .preset-container .harmony-header .extraction-header-title p,
  .container section .saved-color .preset-container .harmony-header .harmony-header-title p,
  .container section .saved-color .preset-container .harmony-header .preset-header-title p,
  .container section .saved-color .preset-container .preset-header .solgrad-header-title p,
  .container section .saved-color .preset-container .preset-header .extraction-header-title p,
  .container section .saved-color .preset-container .preset-header .harmony-header-title p,
  .container section .saved-color .preset-container .preset-header .preset-header-title p {
    font-size: 19px;
  }
  .container section .saved-color .solgrad-container .solgrad-header .solgrad-header-title img,
  .container section .saved-color .solgrad-container .solgrad-header .extraction-header-title img,
  .container section .saved-color .solgrad-container .solgrad-header .harmony-header-title img,
  .container section .saved-color .solgrad-container .solgrad-header .preset-header-title img,
  .container section .saved-color .solgrad-container .extraction-header .solgrad-header-title img,
  .container section .saved-color .solgrad-container .extraction-header .extraction-header-title img,
  .container section .saved-color .solgrad-container .extraction-header .harmony-header-title img,
  .container section .saved-color .solgrad-container .extraction-header .preset-header-title img,
  .container section .saved-color .solgrad-container .harmony-header .solgrad-header-title img,
  .container section .saved-color .solgrad-container .harmony-header .extraction-header-title img,
  .container section .saved-color .solgrad-container .harmony-header .harmony-header-title img,
  .container section .saved-color .solgrad-container .harmony-header .preset-header-title img,
  .container section .saved-color .solgrad-container .preset-header .solgrad-header-title img,
  .container section .saved-color .solgrad-container .preset-header .extraction-header-title img,
  .container section .saved-color .solgrad-container .preset-header .harmony-header-title img,
  .container section .saved-color .solgrad-container .preset-header .preset-header-title img,
  .container section .saved-color .extraction-container .solgrad-header .solgrad-header-title img,
  .container section .saved-color .extraction-container .solgrad-header .extraction-header-title img,
  .container section .saved-color .extraction-container .solgrad-header .harmony-header-title img,
  .container section .saved-color .extraction-container .solgrad-header .preset-header-title img,
  .container section .saved-color .extraction-container .extraction-header .solgrad-header-title img,
  .container section .saved-color .extraction-container .extraction-header .extraction-header-title img,
  .container section .saved-color .extraction-container .extraction-header .harmony-header-title img,
  .container section .saved-color .extraction-container .extraction-header .preset-header-title img,
  .container section .saved-color .extraction-container .harmony-header .solgrad-header-title img,
  .container section .saved-color .extraction-container .harmony-header .extraction-header-title img,
  .container section .saved-color .extraction-container .harmony-header .harmony-header-title img,
  .container section .saved-color .extraction-container .harmony-header .preset-header-title img,
  .container section .saved-color .extraction-container .preset-header .solgrad-header-title img,
  .container section .saved-color .extraction-container .preset-header .extraction-header-title img,
  .container section .saved-color .extraction-container .preset-header .harmony-header-title img,
  .container section .saved-color .extraction-container .preset-header .preset-header-title img,
  .container section .saved-color .harmony-container .solgrad-header .solgrad-header-title img,
  .container section .saved-color .harmony-container .solgrad-header .extraction-header-title img,
  .container section .saved-color .harmony-container .solgrad-header .harmony-header-title img,
  .container section .saved-color .harmony-container .solgrad-header .preset-header-title img,
  .container section .saved-color .harmony-container .extraction-header .solgrad-header-title img,
  .container section .saved-color .harmony-container .extraction-header .extraction-header-title img,
  .container section .saved-color .harmony-container .extraction-header .harmony-header-title img,
  .container section .saved-color .harmony-container .extraction-header .preset-header-title img,
  .container section .saved-color .harmony-container .harmony-header .solgrad-header-title img,
  .container section .saved-color .harmony-container .harmony-header .extraction-header-title img,
  .container section .saved-color .harmony-container .harmony-header .harmony-header-title img,
  .container section .saved-color .harmony-container .harmony-header .preset-header-title img,
  .container section .saved-color .harmony-container .preset-header .solgrad-header-title img,
  .container section .saved-color .harmony-container .preset-header .extraction-header-title img,
  .container section .saved-color .harmony-container .preset-header .harmony-header-title img,
  .container section .saved-color .harmony-container .preset-header .preset-header-title img,
  .container section .saved-color .preset-container .solgrad-header .solgrad-header-title img,
  .container section .saved-color .preset-container .solgrad-header .extraction-header-title img,
  .container section .saved-color .preset-container .solgrad-header .harmony-header-title img,
  .container section .saved-color .preset-container .solgrad-header .preset-header-title img,
  .container section .saved-color .preset-container .extraction-header .solgrad-header-title img,
  .container section .saved-color .preset-container .extraction-header .extraction-header-title img,
  .container section .saved-color .preset-container .extraction-header .harmony-header-title img,
  .container section .saved-color .preset-container .extraction-header .preset-header-title img,
  .container section .saved-color .preset-container .harmony-header .solgrad-header-title img,
  .container section .saved-color .preset-container .harmony-header .extraction-header-title img,
  .container section .saved-color .preset-container .harmony-header .harmony-header-title img,
  .container section .saved-color .preset-container .harmony-header .preset-header-title img,
  .container section .saved-color .preset-container .preset-header .solgrad-header-title img,
  .container section .saved-color .preset-container .preset-header .extraction-header-title img,
  .container section .saved-color .preset-container .preset-header .harmony-header-title img,
  .container section .saved-color .preset-container .preset-header .preset-header-title img {
    width: 28px;
    height: 28px;
  }
  .container section .saved-color .solgrad-container .solgrad-header .solgrad-options select,
  .container section .saved-color .solgrad-container .solgrad-header .extraction-options select,
  .container section .saved-color .solgrad-container .solgrad-header .harmony-options select,
  .container section .saved-color .solgrad-container .solgrad-header .preset-options select,
  .container section .saved-color .solgrad-container .extraction-header .solgrad-options select,
  .container section .saved-color .solgrad-container .extraction-header .extraction-options select,
  .container section .saved-color .solgrad-container .extraction-header .harmony-options select,
  .container section .saved-color .solgrad-container .extraction-header .preset-options select,
  .container section .saved-color .solgrad-container .harmony-header .solgrad-options select,
  .container section .saved-color .solgrad-container .harmony-header .extraction-options select,
  .container section .saved-color .solgrad-container .harmony-header .harmony-options select,
  .container section .saved-color .solgrad-container .harmony-header .preset-options select,
  .container section .saved-color .solgrad-container .preset-header .solgrad-options select,
  .container section .saved-color .solgrad-container .preset-header .extraction-options select,
  .container section .saved-color .solgrad-container .preset-header .harmony-options select,
  .container section .saved-color .solgrad-container .preset-header .preset-options select,
  .container section .saved-color .extraction-container .solgrad-header .solgrad-options select,
  .container section .saved-color .extraction-container .solgrad-header .extraction-options select,
  .container section .saved-color .extraction-container .solgrad-header .harmony-options select,
  .container section .saved-color .extraction-container .solgrad-header .preset-options select,
  .container section .saved-color .extraction-container .extraction-header .solgrad-options select,
  .container section .saved-color .extraction-container .extraction-header .extraction-options select,
  .container section .saved-color .extraction-container .extraction-header .harmony-options select,
  .container section .saved-color .extraction-container .extraction-header .preset-options select,
  .container section .saved-color .extraction-container .harmony-header .solgrad-options select,
  .container section .saved-color .extraction-container .harmony-header .extraction-options select,
  .container section .saved-color .extraction-container .harmony-header .harmony-options select,
  .container section .saved-color .extraction-container .harmony-header .preset-options select,
  .container section .saved-color .extraction-container .preset-header .solgrad-options select,
  .container section .saved-color .extraction-container .preset-header .extraction-options select,
  .container section .saved-color .extraction-container .preset-header .harmony-options select,
  .container section .saved-color .extraction-container .preset-header .preset-options select,
  .container section .saved-color .harmony-container .solgrad-header .solgrad-options select,
  .container section .saved-color .harmony-container .solgrad-header .extraction-options select,
  .container section .saved-color .harmony-container .solgrad-header .harmony-options select,
  .container section .saved-color .harmony-container .solgrad-header .preset-options select,
  .container section .saved-color .harmony-container .extraction-header .solgrad-options select,
  .container section .saved-color .harmony-container .extraction-header .extraction-options select,
  .container section .saved-color .harmony-container .extraction-header .harmony-options select,
  .container section .saved-color .harmony-container .extraction-header .preset-options select,
  .container section .saved-color .harmony-container .harmony-header .solgrad-options select,
  .container section .saved-color .harmony-container .harmony-header .extraction-options select,
  .container section .saved-color .harmony-container .harmony-header .harmony-options select,
  .container section .saved-color .harmony-container .harmony-header .preset-options select,
  .container section .saved-color .harmony-container .preset-header .solgrad-options select,
  .container section .saved-color .harmony-container .preset-header .extraction-options select,
  .container section .saved-color .harmony-container .preset-header .harmony-options select,
  .container section .saved-color .harmony-container .preset-header .preset-options select,
  .container section .saved-color .preset-container .solgrad-header .solgrad-options select,
  .container section .saved-color .preset-container .solgrad-header .extraction-options select,
  .container section .saved-color .preset-container .solgrad-header .harmony-options select,
  .container section .saved-color .preset-container .solgrad-header .preset-options select,
  .container section .saved-color .preset-container .extraction-header .solgrad-options select,
  .container section .saved-color .preset-container .extraction-header .extraction-options select,
  .container section .saved-color .preset-container .extraction-header .harmony-options select,
  .container section .saved-color .preset-container .extraction-header .preset-options select,
  .container section .saved-color .preset-container .harmony-header .solgrad-options select,
  .container section .saved-color .preset-container .harmony-header .extraction-options select,
  .container section .saved-color .preset-container .harmony-header .harmony-options select,
  .container section .saved-color .preset-container .harmony-header .preset-options select,
  .container section .saved-color .preset-container .preset-header .solgrad-options select,
  .container section .saved-color .preset-container .preset-header .extraction-options select,
  .container section .saved-color .preset-container .preset-header .harmony-options select,
  .container section .saved-color .preset-container .preset-header .preset-options select {
    font-size: 15px;
  }
  .container section .saved-color .extraction-container .extraction-items {
    gap: 18px;
  }
  .swatch-item p {
    margin: 10px 0 0 14px;
    font-size: 18px;
  }
  .swatch-item .swatch-hex p,
  .swatch-item .swatch-rgb p {
    margin: 0 0 0 14px;
    font-size: 16px;
  }
  .swatch-item .swatch-hex img,
  .swatch-item .swatch-rgb img {
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
  .solid-item .color-card {
    height: 84px;
  }
  .solid-item .solid-hex {
    margin: 2px 0 0 0;
  }
  .solid-item .solid-hex p {
    margin-left: 8px;
    font-size: 14px;
  }
  .solid-item .solid-hex img {
    margin: 0 8px 0 0;
    width: 22px;
    height: 22px;
  }
  .solid-item .solid-rgb {
    margin: 0 0 2px 0;
  }
  .solid-item .solid-rgb p {
    margin-left: 8px;
    font-size: 14px;
  }
  .solid-item .solid-rgb img {
    margin: 0 8px 0 0;
    width: 22px;
    height: 22px;
  }
  .gradient-item .color-card {
    height: 84px;
  }
  .gradient-item .code-holder {
    margin: 2px 8px;
  }
  .gradient-item .code-holder .color-codes .color-identifier {
    width: 20px;
    height: 20px;
  }
  .gradient-item .code-holder .color-codes .codes {
    margin-left: 10px;
    margin-right: 10px;
  }
  .gradient-item .code-holder .color-codes .codes .code p {
    font-size: 13px;
  }
  .gradient-item .code-holder .color-codes .codes .code img {
    width: 20px;
    height: 20px;
  }
  .extract-modal .modal-container .extract-image {
    width: 94px;
    border-radius: 28px;
  }
  .extract-modal .modal-container .swatch-extracts {
    width: 340px;
    height: 540px;
    margin-top: -48px;
  }
  .extract-modal .modal-container .palette-extracts {
    width: 340px;
    height: 540px;
    margin-top: -48px;
  }
  .extract-modal .modal-container .modal-option {
    width: 38px;
    height: 38px;
  }
  .extract-modal .modal-container .modal-option img {
    width: 16px;
  }
  .dual-item .color-cards {
    height: 84px;
  }
  .dual-item .color-codes {
    margin: 2px 12px;
  }
  .dual-item .color-codes .codes .code p {
    font-size: 14px;
  }
  .dual-item .color-codes .codes .code img {
    width: 22px;
    height: 22px;
  }
  .trial-item .color-cards {
    height: 84px;
  }
  .trial-item .color-codes {
    margin: 2px 8px;
  }
  .trial-item .color-codes .codes .code p {
    font-size: 11px;
  }
  .trial-item .color-codes .codes .code img {
    width: 18px;
    height: 18px;
  }
  .quadral-item .color-cards {
    height: 84px;
  }
  .quadral-item .color-codes {
    margin: 6px 8px;
  }
  .quadral-item .color-codes .codes {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .quadral-item .color-codes .codes p {
    font-size: 12px;
  }
}
