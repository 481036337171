:root {
   --base-color: #d3d3d3;
   --background-color: #ffffff;
   --card-color: #fafafa;
   --text-color: #212121;
   --transition: 0.3s;

   --base-color-rgb: 211, 211, 211;
   --background-color-rgb: 255, 255, 255;
   --card-color-rgb: 250, 250, 250;
   --text-color-rgb: 33, 33, 33;
}
