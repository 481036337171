@keyframes toast-anim {
   0% {
      transform: translateY(20%);
      opacity: 0;
   }
   100% {
      transform: translateY(0);
      opacity: 1;
   }
}
