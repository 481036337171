.toggle-checkbox {
   position: relative;
   width: 40px;
   height: 20px;
   -webkit-appearance: none;
   background: #c6c6c6;
   outline: none;
   cursor: pointer;
   border-radius: 20px;
   transition: var(--transition);
   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);

   // circle
   &::before {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      top: 0px;
      left: 0px;
      border-radius: 20px;
      background-color: var(--card-color);
      transform: scale(1.1);
      transition: var(--transition);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
   }

   // move to right
   &:checked {
      background-color: rgba(var(--text-color-rgb), 0.6);

      &::before {
         left: 22.5px;
         background-color: var(--text-color);
      }
   }
}
