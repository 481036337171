@import './variables';
@import './mixin';
@import './anim';

@import './slider';
@import './checkbox';

html,
body {
   height: 100vh;
   width: 100vw;

   margin: 0;
   padding: 0;

   font-family: 'Quicksand', sans-serif;
   overflow: hidden;

   h1,
   h2,
   h3,
   h4,
   h5,
   p {
      margin: 0;
      padding: 0;
      user-select: none;
   }

   ul,
   li,
   img,
   select {
      user-select: none;
      -webkit-user-drag: none;
   }

   transition: var(--transition);
   color: var(--text-color);
   background-color: var(--base-color);
}

.mobile-warning {
   width: 100vw;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--background-color);

   .warning-body {
      margin: 32px;

      img {
         width: 48px;
         height: 48px;
      }

      h1 {
         margin-top: 8px;
      }

      p {
         margin-top: 10px;

         a {
            font-weight: 600;
         }
      }
   }
}

.container {
   display: flex;
   align-items: flex-start;

   .menu {
      width: 96px;
      height: 100vh;

      transition: var(--transition);
      @include centerItems();
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--background-color);

      div {
         @include centerItems();
         justify-content: flex-start;
         flex-direction: column;

         .logo {
            cursor: pointer;
            margin-top: 24px;
            transition: var(--transition);

            &:hover {
               transform: scale(0.9);
            }
         }

         ul {
            padding: 0;
            margin: 60px 0 0 0;
            list-style: none;

            li {
               margin-bottom: 40px;
               transition: var(--transition);
               cursor: pointer;

               &:hover {
                  @include iconDropShadow(rgba(0, 0, 0, 0));
               }
            }

            .home-item {
               @include iconDropShadow(rgba(0, 0, 0, 0.4));
            }

            .create-item {
               @include iconDropShadow(rgba(0, 0, 0, 0.4));
            }

            .extract-item {
               @include iconDropShadow(rgba(0, 0, 0, 0.4));
            }

            .harmonizer-item {
               @include iconDropShadow(rgba(0, 0, 0, 0.4));
            }

            .saved-item {
               @include iconDropShadow(rgba(0, 0, 0, 0.4));
            }
         }
      }

      .settings {
         cursor: pointer;
         margin-bottom: 50px;
         justify-self: end;
         transition: var(--transition);
         @include iconDropShadow(rgba(0, 0, 0, 0.4));

         &:hover {
            transform: rotate(45deg);
            @include iconDropShadow(rgba(0, 0, 0, 0));
         }
      }
   }

   section {
      display: flex;
      overflow: hidden;
      flex-direction: column;

      width: 100vw;
      height: 100vh;
      margin-left: 1px;

      .header {
         width: 100%;
         height: 96px;

         display: flex;
         transition: var(--transition);
         align-items: center;
         justify-content: space-between;
         background-color: var(--background-color);

         div {
            margin-left: 30px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            h1 {
               color: var(--text-color);
               font-size: 32px;
               font-weight: 900;
               transition: var(--transition);
            }

            p {
               color: #808080;
               font-weight: 900;
               font-size: 14px;
               margin: 0 0 4px 8px;
            }
         }

         img {
            cursor: pointer;
            margin-right: 50px;
            transition: var(--transition);
            @include iconDropShadow(rgba(0, 0, 0, 0.4));

            &:hover {
               transform: rotate(45deg);
               @include iconDropShadow(rgba(0, 0, 0, 0));
            }
         }
      }

      .material-color {
         width: 100%;
         height: 100%;
         margin-top: 1px;

         display: flex;
         overflow: hidden;

         .color-shades {
            width: 100%;
            height: auto;
            padding: 16px 20px;

            display: grid;
            transition: var(--transition);
            grid-template-columns: repeat(5, 1fr);
            background-color: var(--background-color);

            .color-shade {
               transition: var(--transition);
               margin: 16px 20px;
               border-radius: 24px;
               background-color: var(--card-color);
               filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

               div:nth-child(1) {
                  width: 100%;
                  height: 74%;

                  transition: var(--transition);
                  border-radius: 24px;
                  align-items: center;
                  justify-content: center;
                  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
               }

               div:nth-child(2) {
                  display: flex;
                  margin: 5px 20px;

                  div {
                     p:nth-child(1) {
                        transition: var(--transition);
                        font-size: 20px;
                        font-weight: 900;
                        margin-bottom: 2px;
                        color: var(--text-color);
                     }

                     p:nth-child(2) {
                        transition: var(--transition);
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--text-color);
                     }
                  }

                  img {
                     transition: var(--transition);
                  }

                  img:hover {
                     cursor: pointer;
                     transform: scale(0.9);
                  }
               }

               &:hover {
                  filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));

                  div:nth-child(1) {
                     cursor: pointer;
                     filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.15));
                  }
               }
            }
         }

         .color-holder {
            width: 218px;
            padding: 12px;
            margin-left: 1px;

            display: grid;
            transition: var(--transition);
            grid-auto-rows: 72px;
            grid-template-columns: repeat(2, 1fr);
            background-color: var(--background-color);

            overflow-y: auto;
            overflow-x: hidden;
            @include scrollBar();

            .color-item {
               display: flex;
               cursor: pointer;
               transition: var(--transition);
               border-radius: 16px;
               align-items: flex-end;
               margin: 8px 10px 8px 10px;
               justify-content: flex-end;
               @include iconDropShadow(rgba(0, 0, 0, 0.4));

               .selector {
                  width: 10px;
                  height: 10px;
                  transition: var(--transition);
                  border-radius: 50%;
                  margin: 0 10px 10px 0;
                  background-color: var(--card-color);
                  @include iconDropShadow(rgba(0, 0, 0, 0.4));
               }

               &:hover {
                  border-radius: 20px;
                  @include iconDropShadow(rgba(0, 0, 0, 0));

                  .selector {
                     transform: scale(1.1);
                     @include iconDropShadow(rgba(0, 0, 0, 0));
                  }
               }
            }
         }
      }

      .create-color {
         display: flex;
         width: 100%;
         height: 100%;
         margin-top: 1px;

         .solid-section {
            width: 100%;
            height: 100%;
            display: flex;
            transition: var(--transition);
            flex-direction: column;
            background-color: var(--background-color);

            .solid-header {
               display: flex;
               justify-content: space-between;

               .solid-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     transition: var(--transition);
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }
               }

               .solid-alpha-control {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  p {
                     transition: var(--transition);
                     font-size: 20px;
                     margin-right: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }
               }
            }

            .solid-body {
               display: flex;
               height: 100%;
               margin: 32px;
               transition: var(--transition);
               border-radius: 24px;
               background-color: var(--card-color);
               filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

               .solid-color {
                  width: 55%;
                  height: 100%;
                  cursor: pointer;
                  border-radius: inherit;
                  filter: drop-shadow(4px 0 8px rgba(0, 0, 0, 0.2));

                  &:hover {
                     transition: var(--transition) ease-in-out;
                     filter: drop-shadow(2px 0 4px rgba(0, 0, 0, 0.1));
                  }
               }

               .solid-control {
                  width: 45%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  color: var(--text-color);

                  .solid-attributes {
                     margin: 32px;

                     .solid-hex {
                        display: flex;
                        font-size: 18px;
                        font-weight: bold;
                        align-items: center;
                        justify-content: space-between;

                        div {
                           img {
                              transition: var(--transition);
                           }

                           img:hover {
                              cursor: pointer;
                              transform: scale(0.9);
                           }

                           img:nth-child(2) {
                              margin-left: 16px;
                           }
                        }
                     }

                     .solid-rgb {
                        div {
                           img:nth-child(2) {
                              margin-left: 16px;
                           }
                        }
                     }

                     .solid-rgb,
                     .solid-hsv,
                     .solid-hsl,
                     .solid-cmyk {
                        display: flex;
                        margin-top: 16px;
                        font-size: 18px;
                        font-weight: bold;
                        align-items: center;
                        justify-content: space-between;

                        img {
                           transition: var(--transition);
                        }

                        img:hover {
                           cursor: pointer;
                           transform: scale(0.9);
                        }
                     }
                  }

                  .seekbars {
                     margin-bottom: 64px;
                     display: flex;
                     align-self: center;
                     width: 100%;
                     height: 100%;
                     transform: rotate(-90deg);

                     ul:nth-child(1) {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        li {
                           margin-bottom: 2px;
                           font-size: 18px;
                           font-weight: bold;
                           user-select: none;
                           list-style-type: none;
                        }
                     }

                     ul:nth-child(2) {
                        margin: 0 0 0 10px;
                        padding: 0;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        li {
                           list-style-type: none;

                           input[type='range'] {
                              width: 88%;
                           }
                        }
                     }
                  }
               }
            }
         }

         .gradient-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            margin-left: 1px;
            transition: var(--transition);
            background-color: var(--background-color);

            .gradient-header {
               display: flex;
               justify-content: space-between;

               .gradient-header-title {
                  display: flex;
                  margin: 32px 0 0 32px;
                  align-items: center;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }
               }

               .gradient-alpha-control {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  p {
                     font-size: 20px;
                     margin-right: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }
               }
            }

            .gradient-body {
               display: flex;
               height: 100%;
               margin: 32px;
               transition: var(--transition);
               border-radius: 24px;
               background-color: var(--card-color);
               filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

               .gradient-color {
                  width: 55%;
                  height: 100%;
                  cursor: pointer;
                  border-radius: inherit;
                  filter: drop-shadow(4px 0 8px rgba(0, 0, 0, 0.2));

                  &:hover {
                     transition: var(--transition) ease-in-out;
                     filter: drop-shadow(2px 0 4px rgba(0, 0, 0, 0.1));
                  }
               }

               .gradient-control {
                  width: 45%;
                  height: 100%;
                  margin: 16px;
                  display: flex;
                  flex-direction: column;
                  color: var(--text-color);

                  .gradient-top,
                  .gradient-bottom {
                     margin: 16px;
                     height: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: center;

                     .gradient-top-hex,
                     .gradient-bottom-hex {
                        display: flex;
                        font-size: 18px;
                        font-weight: bold;
                        align-items: center;
                        justify-content: space-between;

                        div {
                           img {
                              transition: var(--transition);
                           }

                           img:hover {
                              cursor: pointer;
                              transform: scale(0.9);
                           }

                           img:nth-child(2) {
                              margin-left: 16px;
                           }
                        }
                     }

                     .gradient-top-rgba,
                     .gradient-bottom-rgba {
                        display: flex;
                        margin-top: 16px;
                        font-size: 18px;
                        font-weight: bold;
                        align-items: center;
                        justify-content: space-between;

                        div {
                           img {
                              transition: var(--transition);
                           }

                           img:hover {
                              cursor: pointer;
                              transform: scale(0.9);
                           }

                           img:nth-child(2) {
                              margin-left: 16px;
                           }
                        }
                     }

                     .gradient-top-sliders,
                     .gradient-bottom-sliders {
                        display: flex;
                        height: 100%;

                        ul:nth-child(1) {
                           margin: 0;
                           padding: 0;
                           display: flex;
                           flex-direction: column;
                           justify-content: space-evenly;

                           li {
                              font-size: 18px;
                              font-weight: bold;
                              user-select: none;
                              list-style-type: none;
                           }
                        }

                        ul:nth-child(2) {
                           margin: 0 0 0 14px;
                           padding: 0;
                           width: 100%;
                           display: flex;
                           flex-direction: column;
                           justify-content: space-evenly;

                           li {
                              list-style-type: none;

                              input[type='range'] {
                                 width: 100%;
                              }
                           }
                        }
                     }
                  }

                  .gradient-bottom {
                     margin: 16px 16px 24px 16px;
                  }
               }
            }
         }
      }

      .extract-color {
         display: flex;
         width: 100%;
         height: 100%;
         margin-top: 1px;
         overflow: hidden;

         .swatch-section {
            flex: 1;
            display: flex;
            transition: var(--transition);
            overflow-y: auto;
            flex-direction: column;
            scroll-behavior: smooth !important;
            background-color: var(--background-color);
            @include scrollBar();

            .swatch-header {
               display: flex;
               justify-content: space-between;

               .swatch-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     transition: var(--transition);
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }
               }

               .swatch-reset {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  p {
                     transition: var(--transition);
                     font-size: 20px;
                     margin-right: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }

                  img {
                     height: 28px;
                     width: 28px;
                     transition: var(--transition);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));

                     &:hover {
                        cursor: pointer;
                        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
                     }
                  }
               }
            }

            .swatch-body {
               margin: 32px 32px 0 32px;
               display: flex;
               align-items: center;
               flex-direction: column;

               .swatch-img-chooser {
                  width: 100%;
                  min-height: 256px;
                  cursor: pointer;
                  transition: var(--transition);
                  overflow: hidden;
                  aspect-ratio: 3/1.5;
                  border-radius: 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
                  @include centerItems();

                  &:hover {
                     transition: var(--transition) ease-in-out;
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
                  }

                  .selected-image {
                     position: fixed;
                     max-height: 100%;
                     max-width: 100%;
                  }

                  .logo-info {
                     width: 100%;
                     margin: 20px;
                     display: flex;
                     align-items: flex-start;
                     align-self: flex-start;

                     img {
                        width: 40px;
                        aspect-ratio: 1/1;
                     }

                     p {
                        font-weight: 700;
                        color: #808080;
                        align-self: center;
                        margin: 16px 0 0 8px;
                     }
                  }

                  .save-swatch,
                  .image-url {
                     position: absolute;
                     bottom: 0;
                     right: 0;
                     width: 32px;
                     height: 32px;
                     margin: 12px;
                     transition: var(--transition);
                     border-radius: 50%;
                     background-color: var(--background-color);
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));

                     display: flex;
                     align-items: center;
                     justify-content: center;

                     img {
                        width: 16px;
                        height: 16px;
                     }

                     &:hover {
                        transform: scale(0.9);
                        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                     }
                  }

                  .save-swatch {
                     margin-right: 56px;

                     img {
                        width: 20px;
                        height: 20px;
                     }
                  }
               }

               .extracted-swatches {
                  width: 95%;
                  display: flex;
                  margin-top: 24px;
                  flex-direction: column;
               }

               .swatch-extract-info {
                  width: 95%;
                  color: #808080;

                  p {
                     font-weight: 700;
                     font-size: larger;
                  }

                  ul {
                     li {
                        font-size: large;
                        font-weight: 600;
                        margin-top: 12px;
                     }
                  }
               }
            }
         }

         .palette-section {
            flex: 1;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            overflow-y: auto;
            flex-direction: column;
            scroll-behavior: smooth !important;
            background-color: var(--background-color);
            @include scrollBar();

            .palette-header {
               display: flex;
               justify-content: space-between;

               .palette-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }

               .palette-reset {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  p {
                     font-size: 20px;
                     margin-right: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }

                  img {
                     height: 28px;
                     width: 28px;
                     transition: var(--transition);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));

                     &:hover {
                        cursor: pointer;
                        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
                     }
                  }
               }
            }

            .palette-body {
               margin: 32px 32px 24px 32px;
               display: flex;
               align-items: center;
               flex-direction: column;

               .palette-img-chooser {
                  width: 100%;
                  min-height: 256px;
                  cursor: pointer;
                  transition: var(--transition);
                  overflow: hidden;
                  aspect-ratio: 3/1.5;
                  border-radius: 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
                  @include centerItems();

                  &:hover {
                     transition: var(--transition) ease-in-out;
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
                  }

                  .selected-image {
                     position: fixed;
                     max-height: 100%;
                     max-width: 100%;
                  }

                  .logo-info {
                     width: 100%;
                     margin: 20px;
                     display: flex;
                     align-items: flex-start;
                     align-self: flex-start;

                     img {
                        width: 40px;
                        aspect-ratio: 1/1;
                     }

                     p {
                        font-weight: 700;
                        color: #808080;
                        align-self: center;
                        margin: 16px 0 0 8px;
                     }
                  }

                  .save-palette,
                  .image-url {
                     position: absolute;
                     bottom: 0;
                     right: 0;
                     width: 32px;
                     height: 32px;
                     margin: 12px;
                     transition: var(--transition);
                     border-radius: 50%;
                     background-color: var(--background-color);
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));

                     display: flex;
                     align-items: center;
                     justify-content: center;

                     img {
                        width: 16px;
                        height: 16px;
                     }

                     &:hover {
                        transform: scale(0.9);
                        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                     }
                  }

                  .save-palette {
                     margin-right: 56px;

                     img {
                        width: 20px;
                        height: 20px;
                     }
                  }
               }

               .extracted-palette {
                  gap: 20px;
                  width: 95%;
                  display: grid;
                  margin-top: 24px;
                  grid-template-columns: repeat(3, 1fr);
               }

               .palette-extract-info {
                  width: 95%;
                  color: #808080;

                  p {
                     font-weight: 700;
                     font-size: larger;
                  }

                  ul {
                     li {
                        font-size: large;
                        font-weight: 600;
                        margin-top: 12px;
                     }
                  }
               }
            }
         }

         .manual-section {
            flex: 1;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            overflow-y: auto;
            flex-direction: column;
            scroll-behavior: smooth !important;
            background-color: var(--background-color);
            @include scrollBar();

            .manual-header {
               display: flex;
               justify-content: space-between;

               .manual-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     transition: var(--transition);
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }
               }

               .manual-reset {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  p {
                     transition: var(--transition);
                     font-size: 20px;
                     margin-right: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                  }

                  img {
                     height: 28px;
                     width: 28px;
                     transition: 0.3s;
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));

                     &:hover {
                        cursor: pointer;
                        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
                     }
                  }
               }
            }

            .manual-body {
               margin: 32px;
               height: 100%;
               display: flex;
               align-items: center;
               flex-direction: column;

               .manual-img-background {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  height: 100%;
                  transition: 0.3s;
                  border-radius: 26px 26px 24px 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
                  @include centerItems();

                  .manual-img-card {
                     width: 100%;
                     height: 100%;
                     display: flex;
                     cursor: pointer;
                     transition: 0.3s;
                     overflow: hidden;
                     align-items: center;
                     justify-content: center;
                     border-radius: 24px;
                     background-color: var(--card-color);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

                     .manual-select-logo {
                        width: 40px;
                        height: 40px;
                     }

                     canvas {
                        width: 100%;
                        position: absolute;
                     }

                     &:hover {
                        filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
                     }

                     .image-url {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 32px;
                        height: 32px;
                        margin: 12px;
                        transition: 0.3s;
                        border-radius: 50%;
                        background-color: var(--background-color);
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                           width: 16px;
                           height: 16px;
                        }

                        &:hover {
                           transform: scale(0.9);
                           filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                        }
                     }
                  }

                  .manual-color-card {
                     margin: 20px 0;
                     width: 100%;
                     display: flex;
                     color: var(--text-color);
                     justify-content: space-evenly;

                     p {
                        font-size: larger;
                        font-weight: 800;
                     }

                     .manual-color-hex {
                        display: flex;
                        align-items: center;

                        img {
                           margin-left: 5px;
                           transition: 0.3s;

                           &:hover {
                              cursor: pointer;
                              transform: scale(0.9);
                           }
                        }
                     }

                     .manual-choose-image {
                        width: 42px;
                        height: 42px;
                        transition: 0.3s;
                        border-radius: 50%;
                        background-color: var(--background-color);
                        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
                        @include centerItems();

                        img {
                           width: 22px;
                           height: 22px;
                        }

                        &:hover {
                           cursor: pointer;
                           transform: scale(0.9);
                           filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
                        }
                     }

                     .manual-color-rgb {
                        display: flex;
                        align-items: center;

                        img {
                           margin-left: 5px;
                           transition: 0.3s;

                           &:hover {
                              cursor: pointer;
                              transform: scale(0.9);
                           }
                        }
                     }
                  }

                  &:hover {
                     cursor: pointer;
                  }
               }
            }
         }
      }

      .harmonize-color {
         display: flex;
         width: 100%;
         height: 100%;
         margin-top: 1px;
         overflow-x: auto;
         overflow-y: hidden;
         scroll-behavior: smooth !important;
         @include scrollBar();

         .complement-container {
            width: 100%;
            height: 100%;
            display: flex;
            transition: var(--transition);
            flex-direction: column;
            background-color: var(--background-color);

            .complement-header {
               display: flex;
               justify-content: space-between;

               .complement-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }
            }

            .complement-body {
               height: 100%;
               margin: 32px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;

               .colors-container {
                  width: 100%;
                  transition: var(--transition);
                  border-radius: 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

                  .colors {
                     display: flex;
                     width: 100%;
                     height: 120px;
                     cursor: pointer;
                     overflow: hidden;
                     transition: var(--transition);
                     border-radius: inherit;
                     background-color: var(--background-color);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

                     div {
                        width: 100%;
                        height: 100%;
                     }
                  }

                  .codes {
                     width: 100%;
                     display: flex;
                     color: var(--text-color);
                     margin: 12px 0;

                     div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                           cursor: pointer;
                           font-weight: 700;
                           transition: var(--transition);
                        }

                        p:nth-child(2) {
                           margin-top: 2px;
                        }
                     }
                  }

                  .separator {
                     height: 1px;
                     width: 100%;
                     margin-top: 16px;
                     transition: var(--transition);
                     background-color: var(--base-color);
                  }

                  .info {
                     margin: 14px;
                     font-size: 14px;
                     color: var(--text-color);
                     transition: var(--transition);
                  }

                  .invert-mode {
                     display: flex;
                     align-items: center;
                     color: var(--text-color);
                     margin: 8px 16px 16px 16px;
                     justify-content: space-evenly;

                     p {
                        font-size: medium;
                        font-weight: 700;
                        transition: var(--transition);
                     }

                     input {
                        margin: 4px 0 0 10px;
                     }

                     .save-complement {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        transition: var(--transition);
                        margin-left: 24px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--background-color);
                        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));

                        img {
                           width: 20px;
                           height: 20px;
                        }

                        &:hover {
                           cursor: pointer;
                           transform: scale(0.9);
                           filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                        }
                     }
                  }

                  &:hover {
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

                     .colors {
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
                     }
                  }
               }

               .color-wheel {
                  display: flex;
                  aspect-ratio: 1/1;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

                  &:hover {
                     cursor: pointer;
                     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
                  }

                  img {
                     width: 10px;
                     height: 10px;
                     aspect-ratio: 1/1;
                     -webkit-user-drag: none;
                     -khtml-user-drag: none;
                     -moz-user-drag: none;
                     -o-user-drag: none;
                     user-select: none;
                     position: absolute;
                  }
               }
            }
         }

         .split-container {
            width: 100%;
            height: 100%;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            flex-direction: column;
            background-color: var(--background-color);

            .split-header {
               display: flex;
               justify-content: space-between;

               .split-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }
            }

            .split-body {
               height: 100%;
               margin: 32px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;

               .colors-container {
                  width: 100%;
                  transition: var(--transition);
                  border-radius: 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

                  .colors {
                     display: flex;
                     width: 100%;
                     height: 120px;
                     cursor: pointer;
                     overflow: hidden;
                     transition: var(--transition);
                     border-radius: inherit;
                     background-color: var(--background-color);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

                     div {
                        width: 100%;
                        height: 100%;
                     }
                  }

                  .codes {
                     width: 100%;
                     display: flex;
                     color: var(--text-color);
                     margin: 12px 0;

                     div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                           cursor: pointer;
                           font-size: 14px;
                           font-weight: 700;
                           transition: var(--transition);
                        }

                        p:nth-child(2) {
                           margin-top: 4px;
                        }
                     }
                  }

                  .separator {
                     height: 1px;
                     width: 100%;
                     margin-top: 16px;
                     transition: var(--transition);
                     background-color: var(--base-color);
                  }

                  .info {
                     margin: 14px;
                     font-size: 14px;
                     color: var(--text-color);
                     transition: var(--transition);
                  }

                  .invert-mode {
                     display: flex;
                     align-items: center;
                     color: var(--text-color);
                     margin: 8px 16px 16px 16px;
                     justify-content: space-evenly;

                     p {
                        font-size: medium;
                        font-weight: 700;
                        transition: var(--transition);
                     }

                     input {
                        margin: 4px 0 0 10px;
                     }

                     .save-split {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        transition: var(--transition);
                        margin-left: 24px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--background-color);
                        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));

                        img {
                           width: 20px;
                           height: 20px;
                        }

                        &:hover {
                           cursor: pointer;
                           transform: scale(0.9);
                           filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                        }
                     }
                  }

                  &:hover {
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

                     .colors {
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
                     }
                  }
               }

               .color-wheel {
                  display: flex;
                  aspect-ratio: 1/1;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

                  &:hover {
                     cursor: pointer;
                     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
                  }

                  img {
                     width: 10px;
                     height: 10px;
                     aspect-ratio: 1/1;
                     -webkit-user-drag: none;
                     -khtml-user-drag: none;
                     -moz-user-drag: none;
                     -o-user-drag: none;
                     user-select: none;
                     position: absolute;
                  }
               }
            }
         }

         .analogous-container {
            width: 100%;
            height: 100%;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            flex-direction: column;
            background-color: var(--background-color);

            .analogous-header {
               display: flex;
               justify-content: space-between;

               .analogous-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }
            }

            .analogous-body {
               height: 100%;
               margin: 32px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;

               .colors-container {
                  width: 100%;
                  transition: var(--transition);
                  border-radius: 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

                  .colors {
                     display: flex;
                     width: 100%;
                     height: 120px;
                     cursor: pointer;
                     overflow: hidden;
                     transition: var(--transition);
                     border-radius: inherit;
                     background-color: var(--background-color);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

                     div {
                        width: 100%;
                        height: 100%;
                     }
                  }

                  .codes {
                     width: 100%;
                     display: flex;
                     color: var(--text-color);
                     margin: 12px 0;

                     div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                           cursor: pointer;
                           font-size: 14px;
                           font-weight: 700;
                           transition: var(--transition);
                        }

                        p:nth-child(2) {
                           margin-top: 4px;
                        }
                     }
                  }

                  .separator {
                     height: 1px;
                     width: 100%;
                     margin-top: 16px;
                     transition: var(--transition);
                     background-color: var(--base-color);
                  }

                  .info {
                     margin: 14px;
                     font-size: 14px;
                     color: var(--text-color);
                     transition: var(--transition);
                  }

                  .invert-mode {
                     display: flex;
                     align-items: center;
                     color: var(--text-color);
                     margin: 8px 16px 16px 16px;
                     justify-content: space-evenly;

                     p {
                        font-size: medium;
                        font-weight: 700;
                        transition: var(--transition);
                     }

                     input {
                        margin: 4px 0 0 10px;
                     }

                     .save-analogous {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        transition: var(--transition);
                        margin-left: 24px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--background-color);
                        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));

                        img {
                           width: 20px;
                           height: 20px;
                        }

                        &:hover {
                           cursor: pointer;
                           transform: scale(0.9);
                           filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                        }
                     }
                  }

                  &:hover {
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

                     .colors {
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
                     }
                  }
               }

               .color-wheel {
                  display: flex;
                  aspect-ratio: 1/1;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

                  &:hover {
                     cursor: pointer;
                     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
                  }

                  img {
                     width: 10px;
                     height: 10px;
                     aspect-ratio: 1/1;
                     -webkit-user-drag: none;
                     -khtml-user-drag: none;
                     -moz-user-drag: none;
                     -o-user-drag: none;
                     user-select: none;
                     position: absolute;
                  }
               }
            }
         }

         .triadic-container {
            width: 100%;
            height: 100%;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            flex-direction: column;
            background-color: var(--background-color);

            .triadic-header {
               display: flex;
               justify-content: space-between;

               .triadic-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }
            }

            .triadic-body {
               height: 100%;
               margin: 32px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;

               .colors-container {
                  width: 100%;
                  transition: var(--transition);
                  border-radius: 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

                  .colors {
                     display: flex;
                     width: 100%;
                     height: 120px;
                     cursor: pointer;
                     overflow: hidden;
                     transition: var(--transition);
                     border-radius: inherit;
                     background-color: var(--background-color);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

                     div {
                        width: 100%;
                        height: 100%;
                     }
                  }

                  .codes {
                     width: 100%;
                     display: flex;
                     color: var(--text-color);
                     margin: 12px 0;

                     div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                           cursor: pointer;
                           font-size: 14px;
                           font-weight: 700;
                           transition: var(--transition);
                        }

                        p:nth-child(2) {
                           margin-top: 4px;
                        }
                     }
                  }

                  .separator {
                     height: 1px;
                     width: 100%;
                     margin-top: 16px;
                     transition: var(--transition);
                     background-color: var(--base-color);
                  }

                  .info {
                     margin: 14px;
                     font-size: 14px;
                     color: var(--text-color);
                     transition: var(--transition);
                  }

                  .invert-mode {
                     display: flex;
                     align-items: center;
                     color: var(--text-color);
                     margin: 8px 16px 16px 16px;
                     justify-content: space-evenly;

                     p {
                        font-size: medium;
                        font-weight: 700;
                        transition: var(--transition);
                     }

                     input {
                        margin: 4px 0 0 10px;
                     }

                     .save-triadic {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        transition: var(--transition);
                        margin-left: 24px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--background-color);
                        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));

                        img {
                           width: 20px;
                           height: 20px;
                        }

                        &:hover {
                           cursor: pointer;
                           transform: scale(0.9);
                           filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                        }
                     }
                  }

                  &:hover {
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

                     .colors {
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
                     }
                  }
               }

               .color-wheel {
                  display: flex;
                  aspect-ratio: 1/1;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

                  &:hover {
                     cursor: pointer;
                     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
                  }

                  img {
                     width: 10px;
                     height: 10px;
                     aspect-ratio: 1/1;
                     -webkit-user-drag: none;
                     -khtml-user-drag: none;
                     -moz-user-drag: none;
                     -o-user-drag: none;
                     user-select: none;
                     position: absolute;
                  }
               }
            }
         }

         .tetradic-container {
            width: 100%;
            height: 100%;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            flex-direction: column;
            background-color: var(--background-color);

            .tetradic-header {
               display: flex;
               justify-content: space-between;

               .tetradic-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }
            }

            .tetradic-body {
               height: 100%;
               margin: 32px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;

               .colors-container {
                  width: 100%;
                  transition: var(--transition);
                  border-radius: 24px;
                  background-color: var(--card-color);
                  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

                  .colors {
                     display: flex;
                     width: 100%;
                     height: 120px;
                     cursor: pointer;
                     overflow: hidden;
                     transition: var(--transition);
                     border-radius: inherit;
                     background-color: var(--background-color);
                     filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));

                     div {
                        width: 100%;
                        height: 100%;
                     }
                  }

                  .codes {
                     width: 100%;
                     display: flex;
                     color: var(--text-color);
                     margin: 12px 0;

                     div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                           cursor: pointer;
                           font-size: 11px;
                           font-weight: 800;
                           transition: var(--transition);
                        }

                        p:nth-child(2) {
                           margin-top: 6px;
                        }
                     }
                  }

                  .separator {
                     height: 1px;
                     width: 100%;
                     margin-top: 16px;
                     transition: var(--transition);
                     background-color: var(--base-color);
                  }

                  .info {
                     margin: 14px;
                     font-size: 14px;
                     color: var(--text-color);
                     transition: var(--transition);
                  }

                  .invert-mode {
                     display: flex;
                     align-items: center;
                     color: var(--text-color);
                     margin: 8px 16px 16px 16px;
                     justify-content: space-evenly;

                     p {
                        font-size: medium;
                        font-weight: 700;
                        transition: var(--transition);
                     }

                     input {
                        margin: 4px 0 0 10px;
                     }

                     .save-tetradic {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        transition: var(--transition);
                        margin-left: 24px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--background-color);
                        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));

                        img {
                           width: 20px;
                           height: 20px;
                        }

                        &:hover {
                           cursor: pointer;
                           transform: scale(0.9);
                           filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                        }
                     }
                  }

                  &:hover {
                     filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

                     .colors {
                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
                     }
                  }
               }

               .color-wheel {
                  display: flex;
                  aspect-ratio: 1/1;
                  border-radius: 50%;
                  align-items: center;
                  justify-content: center;
                  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

                  &:hover {
                     cursor: pointer;
                     box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
                  }

                  img {
                     width: 10px;
                     height: 10px;
                     aspect-ratio: 1/1;
                     -webkit-user-drag: none;
                     -khtml-user-drag: none;
                     -moz-user-drag: none;
                     -o-user-drag: none;
                     user-select: none;
                     position: absolute;
                  }
               }
            }
         }
      }

      .saved-color {
         display: flex;
         width: 100%;
         height: 100%;
         margin-top: 1px;
         overflow: hidden;

         .solgrad-container {
            width: 100%;
            display: flex;
            transition: var(--transition);
            overflow-y: auto;
            overflow-x: hidden;
            flex-direction: column;
            scroll-behavior: smooth !important;
            background-color: var(--background-color);
            @include scrollBar();

            .solgrad-header {
               display: flex;
               justify-content: space-between;

               .solgrad-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }

               .solgrad-options {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  select {
                     outline: none;
                     border: none;
                     font-size: 16px;
                     font-weight: 600;
                     padding: 6px 10px;
                     transition: var(--transition);
                     border-radius: 12px;
                     color: var(--text-color);
                     background-color: var(--card-color);
                     font-family: 'Quicksand', sans-serif;
                     filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

                     &:hover {
                        cursor: pointer;
                        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                     }
                  }
               }
            }

            .solgrad-solid-items {
               gap: 20px;
               margin: 32px;
               display: grid;
               align-items: center;
               grid-template-columns: repeat(2, 1fr);
            }

            .solgrad-gradient-items {
               margin: 32px 32px 10px 32px;
               display: flex;
               align-items: center;
               flex-direction: column;
            }

            .solgrad-loading {
               margin: 32px;
               height: 100%;
               display: flex;
               @include centerItems();

               p {
                  font-size: large;
                  font-weight: 700;
                  opacity: 0.8;
                  color: var(--text-color);
                  transition: var(--transition);
               }
            }
         }

         .extraction-container {
            width: 100%;
            height: 100%;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            overflow-y: auto;
            overflow-x: hidden;
            flex-direction: column;
            scroll-behavior: smooth !important;
            background-color: var(--background-color);
            @include scrollBar();

            .extraction-header {
               display: flex;
               justify-content: space-between;

               .extraction-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }

               .extraction-options {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  select {
                     outline: none;
                     border: none;
                     font-size: 16px;
                     font-weight: 600;
                     padding: 6px 10px;
                     transition: var(--transition);
                     border-radius: 12px;
                     color: var(--text-color);
                     background-color: var(--card-color);
                     font-family: 'Quicksand', sans-serif;
                     filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

                     &:hover {
                        cursor: pointer;
                        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                     }
                  }
               }
            }

            .extraction-items {
               gap: 20px;
               margin: 32px;
               display: grid;
               grid-template-columns: repeat(2, 1fr);

               img {
                  width: 100%;
                  display: flex;
                  transition: var(--transition);
                  object-fit: cover;
                  aspect-ratio: 3/2;
                  border-radius: 24px;
                  align-self: center;
                  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

                  &:hover {
                     cursor: pointer;
                     filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                  }
               }
            }

            .extraction-loading {
               margin: 32px;
               height: 100%;
               display: flex;
               @include centerItems();

               p {
                  font-size: large;
                  font-weight: 700;
                  opacity: 0.8;
                  color: var(--text-color);
                  transition: var(--transition);
               }
            }
         }

         .harmony-container {
            width: 100%;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            overflow-y: auto;
            overflow-x: hidden;
            flex-direction: column;
            scroll-behavior: smooth !important;
            background-color: var(--background-color);
            @include scrollBar();

            .harmony-header {
               display: flex;
               justify-content: space-between;

               .harmony-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }

               .harmony-options {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  select {
                     outline: none;
                     border: none;
                     font-size: 16px;
                     font-weight: 600;
                     padding: 6px 10px;
                     transition: var(--transition);
                     border-radius: 12px;
                     color: var(--text-color);
                     background-color: var(--card-color);
                     font-family: 'Quicksand', sans-serif;
                     filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

                     &:hover {
                        cursor: pointer;
                        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                     }
                  }
               }
            }

            .data-items {
               margin: 32px 32px 10px 32px;
               display: flex;
               align-items: center;
               flex-direction: column;
            }

            .data-loading {
               margin: 32px;
               height: 100%;
               display: flex;
               @include centerItems();

               p {
                  font-size: large;
                  font-weight: 700;
                  opacity: 0.8;
                  color: var(--text-color);
                  transition: var(--transition);
               }
            }
         }

         .preset-container {
            width: 100%;
            height: 100%;
            display: flex;
            margin-left: 1px;
            transition: var(--transition);
            overflow-y: auto;
            overflow-x: hidden;
            flex-direction: column;
            scroll-behavior: smooth !important;
            background-color: var(--background-color);
            @include scrollBar();

            .preset-header {
               display: flex;
               justify-content: space-between;

               .preset-header-title {
                  display: flex;
                  align-items: center;
                  margin: 32px 0 0 32px;

                  p {
                     font-size: 20px;
                     margin-left: 10px;
                     font-weight: bold;
                     color: var(--text-color);
                     transition: var(--transition);
                  }
               }

               .preset-options {
                  display: flex;
                  align-items: center;
                  margin: 32px 32px 0 0;

                  select {
                     outline: none;
                     border: none;
                     font-size: 16px;
                     font-weight: 600;
                     padding: 6px 10px;
                     transition: var(--transition);
                     border-radius: 12px;
                     color: var(--text-color);
                     background-color: var(--card-color);
                     font-family: 'Quicksand', sans-serif;
                     filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

                     &:hover {
                        cursor: pointer;
                        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
                     }
                  }
               }
            }

            .preset-gradient-items {
               margin: 32px 32px 10px 32px;
               display: flex;
               align-items: center;
               flex-direction: column;
            }

            .preset-quadrant-items {
               margin: 32px 32px 10px 32px;
               display: flex;
               align-items: center;
               flex-direction: column;
            }
         }
      }

      footer {
         display: flex;
         width: 100%;
         height: 72px;

         margin-top: 1px;
         transition: var(--transition);
         flex-direction: row;
         @include centerItems();
         justify-content: space-between;
         background-color: var(--background-color);

         p {
            color: #808080;
            font-size: 18px;
            font-weight: 600;
            margin-left: 50px;

            span {
               color: #606060;
               font-weight: 900;
               cursor: pointer;
            }
         }

         ul {
            padding: 0;
            display: flex;
            list-style: none;
            margin: 8px 50px 0 0;

            li {
               cursor: pointer;
               margin-left: 24px;
               transition: var(--transition);
            }

            li:nth-child(1) {
               @include iconDropShadow(rgba(14, 118, 168, 0.5));

               &:hover {
                  @include iconDropShadow(rgba(0, 0, 0, 0));
               }
            }

            li:nth-child(2) {
               @include iconDropShadow(rgba(23, 21, 21, 0.5));

               &:hover {
                  @include iconDropShadow(rgba(0, 0, 0, 0));
               }
            }

            li:nth-child(3) {
               @include iconDropShadow(rgba(255, 51, 51, 0.5));

               &:hover {
                  @include iconDropShadow(rgba(0, 0, 0, 0));
               }
            }

            li:nth-child(4) {
               @include iconDropShadow(rgba(59, 89, 152, 0.5));

               &:hover {
                  @include iconDropShadow(rgba(0, 0, 0, 0));
               }
            }

            li:nth-child(5) {
               @include iconDropShadow(rgba(0, 172, 238, 0.5));

               &:hover {
                  @include iconDropShadow(rgba(0, 0, 0, 0));
               }
            }

            li:nth-child(6) {
               @include iconDropShadow(rgba(255, 51, 51, 0.5));

               &:hover {
                  @include iconDropShadow(rgba(0, 0, 0, 0));
               }
            }
         }
      }
   }
}

.solid-item {
   width: 100%;
   transition: var(--transition);
   border-radius: 24px 24px 20px 20px;
   background-color: var(--card-color);
   filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

   .color-card {
      height: 100px;
      border-radius: 24px;
      transition: var(--transition);
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
   }

   .solid-hex {
      display: flex;
      margin: 2px 0 0 14px;
      align-items: center;
      justify-content: space-between;

      p {
         font-size: 15px;
         font-weight: 700;
         color: var(--text-color);
         transition: var(--transition);
      }

      img {
         width: 24px;
         height: 24px;
         transition: var(--transition);
         margin: 0 12px 0 5px;

         &:hover {
            cursor: pointer;
            transform: scale(0.9);
         }
      }
   }

   .solid-rgb {
      display: flex;
      margin: 0 0 0 14px;
      align-items: center;
      justify-content: space-between;

      p {
         font-size: 15px;
         font-weight: 700;
         color: var(--text-color);
         transition: var(--transition);
      }

      img {
         width: 24px;
         height: 24px;
         transition: 0.3s;
         margin: 0 12px 0 5px;

         &:hover {
            cursor: pointer;
            transform: scale(0.9);
         }
      }
   }

   &:hover {
      filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));

      .color-card {
         cursor: pointer;
         filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
      }
   }
}

.gradient-item {
   width: 100%;
   transition: var(--transition);
   overflow: hidden;
   margin-bottom: 20px;
   border-radius: 24px 24px 20px 20px;
   background-color: var(--card-color);
   filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

   .color-card {
      height: 100px;
      transition: var(--transition);
      border-radius: 24px;
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
   }

   .code-holder {
      display: flex;
      margin: 2px 14px;
      align-items: center;
      justify-content: space-between;

      .color-codes {
         display: flex;
         align-items: center;

         .color-identifier {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            transition: var(--transition);
            filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

            &:hover {
               cursor: pointer;
               filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
            }
         }

         .codes {
            display: flex;
            margin-left: 10px;
            margin-right: 10px;
            flex-direction: column;

            .code {
               display: flex;
               align-items: center;
               justify-content: space-between;

               p {
                  font-size: 15px;
                  font-weight: 700;
                  color: var(--text-color);
                  transition: var(--transition);
               }

               img {
                  width: 24px;
                  height: 24px;
                  transition: var(--transition);
                  margin-left: 5px;

                  &:hover {
                     cursor: pointer;
                     transform: scale(0.9);
                  }
               }
            }
         }
      }
   }

   &:hover {
      filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));

      .color-card {
         cursor: pointer;
         filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
      }
   }
}

.swatch-item {
   width: 100%;
   transition: var(--transition);
   margin-bottom: 20px;
   border-radius: 24px;
   filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));

   &:hover {
      cursor: pointer;
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
   }

   p {
      margin: 12px 0 0 16px;
      font-size: 20px;
      font-weight: 700;
      transition: var(--transition);
   }

   .swatch-hex {
      display: flex;
      margin-top: 2px;
      align-items: center;

      p {
         margin: 0 0 0 16px;
         font-size: 17px;
         transition: var(--transition);
      }

      img {
         transition: var(--transition);
         margin-left: 5px;

         &:hover {
            cursor: pointer;
            transform: scale(0.9);
         }
      }
   }

   .swatch-rgb {
      display: flex;
      margin: 2px 0 12px 0;
      align-items: center;

      p {
         margin: 0 0 0 16px;
         font-size: 17px;
         transition: var(--transition);
      }

      img {
         transition: var(--transition);
         margin-left: 5px;

         &:hover {
            cursor: pointer;
            transform: scale(0.9);
         }
      }
   }
}

.dual-item {
   width: 100%;
   transition: var(--transition);
   overflow: hidden;
   margin-bottom: 20px;
   border-radius: 24px 24px 20px 20px;
   background-color: var(--card-color);
   filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

   .color-cards {
      display: flex;
      transition: var(--transition);
      overflow: hidden;
      border-radius: 24px;
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));

      .color-card {
         width: 100%;
         height: 100px;
         display: flex;
      }

      &:hover {
         cursor: pointer;
         filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
      }
   }

   .color-codes {
      display: flex;
      align-items: center;
      margin: 2px 14px;
      justify-content: space-between;

      .codes {
         display: flex;
         flex-direction: column;

         .code {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
               font-size: 15px;
               font-weight: 700;
               color: var(--text-color);
               transition: var(--transition);
            }

            img {
               width: 24px;
               height: 24px;
               transition: var(--transition);
               margin-left: 5px;

               &:hover {
                  cursor: pointer;
                  transform: scale(0.9);
               }
            }
         }
      }
   }

   &:hover {
      filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));

      .color-cards {
         filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
      }
   }
}

.trial-item {
   width: 100%;
   transition: var(--transition);
   overflow: hidden;
   margin-bottom: 20px;
   border-radius: 24px 24px 20px 20px;
   background-color: var(--card-color);
   filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

   .color-cards {
      display: flex;
      transition: var(--transition);
      overflow: hidden;
      border-radius: 24px;
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));

      .color-card {
         width: 100%;
         height: 100px;
         display: flex;
      }

      &:hover {
         cursor: pointer;
         filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
      }
   }

   .color-codes {
      display: flex;
      align-items: center;
      margin: 2px 14px;
      justify-content: space-between;

      .codes {
         display: flex;
         flex-direction: column;

         .code {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
               font-size: 14px;
               font-weight: 700;
               color: var(--text-color);
               transition: var(--transition);
            }

            img {
               width: 22px;
               height: 22px;
               transition: var(--transition);
               margin-left: 4px;

               &:hover {
                  cursor: pointer;
                  transform: scale(0.9);
               }
            }
         }
      }
   }

   &:hover {
      filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));

      .color-cards {
         filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
      }
   }
}

.quadral-item {
   width: 100%;
   transition: var(--transition);
   overflow: hidden;
   margin-bottom: 20px;
   border-radius: 24px 24px 20px 20px;
   background-color: var(--card-color);
   filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.15));

   .color-cards {
      display: flex;
      transition: var(--transition);
      overflow: hidden;
      border-radius: 24px;
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));

      .color-card {
         width: 100%;
         height: 100px;
         display: flex;
      }

      &:hover {
         cursor: pointer;
         filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
      }
   }

   .color-codes {
      display: flex;
      margin: 4px 12px;
      align-items: center;
      justify-content: space-between;

      .codes {
         display: flex;
         align-items: flex-start;
         flex-direction: column;

         p {
            font-size: 14px;
            font-weight: 700;
            color: var(--text-color);
            transition: var(--transition);

            &:hover {
               cursor: pointer;
            }
         }

         p:nth-child(2) {
            margin-top: 4px;
         }
      }
   }

   &:hover {
      filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.15));

      .color-cards {
         filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
      }
   }
}

.disabled {
   opacity: 0.5;

   &:hover {
      opacity: 0.5;
   }
}

.toast {
   display: none;
   position: absolute;
   bottom: 0;
   right: 0;
   margin: 0 370px 8px 0;
   border-radius: 10px;
   padding: 10px 16px;
   font-size: medium;
   transition: var(--transition);
   animation: var(--transition) ease 0s 1 toast-anim;
   z-index: 2;
   user-select: none;
   color: var(--background-color);
   background-color: var(--text-color);
}

.create-modal,
.url-modal {
   display: flex;
   position: fixed;
   align-items: center;
   justify-content: center;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
   background-color: rgba(var(--background-color-rgb), 0.2);
   backdrop-filter: blur(12px);

   .modal-container {
      width: 360px;
      border-radius: 28px 28px 24px 24px;
      color: var(--text-color);
      background-color: var(--text-color);

      .modal-content {
         border-radius: 24px;
         padding: 16px 20px 20px 20px;
         filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
         background-color: var(--card-color);

         .modal-header {
            display: flex;
            user-select: none;
            align-items: center;
            justify-content: space-between;

            img {
               width: 20px;
               height: 20px;
               transition: 0.2s;
            }

            img:hover {
               transform: scale(0.9);
               cursor: pointer;
            }
         }

         .modal-body {
            display: flex;
            margin-top: 16px;
            flex-direction: column;
            align-items: center;

            ul {
               margin: 0;
               user-select: none;
               padding: 0 10px 0 20px;

               li {
                  margin-top: 8px;
                  font-size: 18px;
                  font-weight: 600;
               }
            }

            input {
               width: 56%;
               border: none;
               font-size: 22px;
               margin-top: 24px;
               text-align: center;
               color: var(--text-color);
               background-color: var(--card-color);
               padding: 10px 10px 5px 10px;
               border-bottom: 2px solid #808080;

               &:focus {
                  outline: none;
               }
            }
         }
      }

      .modal-button {
         padding: 8px 0 12px 0;

         p {
            user-select: none;
            text-align: center;
            font-size: large;
            font-weight: 900;
            transition: 0.2s;
            color: var(--background-color);

            &:hover {
               cursor: pointer;
               transform: scale(0.9);
            }
         }
      }
   }
}

.settings-modal {
   display: flex;
   position: fixed;
   align-items: center;
   justify-content: center;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
   background-color: rgba(var(--background-color-rgb), 0.2);
   backdrop-filter: blur(12px);

   .modal-container {
      width: 512px;
      height: auto;
      border-radius: 24px;
      overflow: hidden;
      color: var(--text-color);
      background-color: var(--card-color);
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));

      .settings-modal-header {
         padding: 12px 16px;
         display: flex;
         border-radius: 24px 24px 0 0;
         justify-content: space-between;

         img {
            width: 20px;
            transition: var(--transition);

            &:hover {
               cursor: pointer;
               transform: scale(0.9);
            }
         }
      }

      .settings-modal-body {
         width: 100%;
         height: 100%;

         overflow-y: scroll;
         @include scrollBar();

         h3 {
            margin: 16px 0 0 24px;
         }

         .settings-category {
            margin: 8px 24px 0 24px;
            padding: 16px;
            border-radius: 18px;
            background-color: var(--background-color);
            filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
         }

         .theme {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title-desc {
               width: 86%;

               p {
                  margin-top: 4px;
                  font-size: 15px;
               }
            }
         }

         .palette {
            .palette_seekbar_count {
               width: 100%;
               margin-top: 12px;
               display: flex;
               align-items: center;
               justify-content: space-between;

               h3 {
                  width: 30px;
                  margin: 0 0 4px 12px;
               }
            }

            .separator {
               height: 1px;
               width: 100%;
               margin-top: 16px;
               transition: var(--transition);
               background-color: var(--base-color);
            }

            .info {
               margin-top: 8px;
               font-size: 14px;
            }
         }

         .feedback {
            display: flex;
            justify-content: space-between;

            p {
               width: 86%;
            }

            img {
               margin-right: 4px;
               align-self: center;
               transition: var(--transition);
               width: 28px;
               height: 28px;

               &:hover {
                  cursor: pointer;
                  transform: scale(0.9);
               }
            }
         }

         .about-app {
            display: flex;
            justify-content: start;
            align-items: flex-end;
            margin: 18px 0 16px 24px;

            .app-logo {
               margin-bottom: 2px;
               width: 30px;
               height: 30px;
            }

            .app-name {
               padding-bottom: 0;
               margin-left: 8px;
               font-weight: bolder;
               font-size: 18px;
            }

            .app-version {
               margin: 0 0 2px 4px;
               font-weight: 800;
               font-size: 12px;
               opacity: 50%;
            }
         }
      }
   }
}

.extract-modal {
   display: flex;
   position: fixed;
   align-items: center;
   justify-content: center;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
   background-color: rgba(var(--background-color-rgb), 0.2);
   backdrop-filter: blur(12px);

   .modal-container {
      display: flex;
      flex-direction: column;
      color: var(--text-color);

      .extract-image {
         z-index: 1;
         width: 128px;
         transition: 0.3s;
         object-fit: cover;
         aspect-ratio: 1/1;
         border-radius: 32px;
         filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

         &:hover {
            cursor: pointer;
            filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
         }
      }

      .swatch-extracts {
         width: 380px;
         height: 640px;
         padding: 12px;
         display: flex;
         margin-top: -64px;
         border-radius: 32px;
         flex-direction: column;
         background-color: var(--card-color);
         filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

         overflow-y: auto;
         scroll-behavior: smooth !important;

         -ms-overflow-style: none;
         scrollbar-width: none;

         &::-webkit-scrollbar {
            display: none;
         }

         .swatch-item {
            margin-bottom: 12px;
         }
      }

      .palette-extracts {
         width: 380px;
         height: 640px;
         margin-top: -64px;
         border-radius: 32px;
         flex-direction: column;
         padding: 12px 12px 24px 12px;
         background-color: var(--card-color);
         filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

         gap: 12px;
         display: grid;
         grid-template-columns: repeat(2, 1fr);

         overflow-y: auto;
         scroll-behavior: smooth !important;

         -ms-overflow-style: none;
         scrollbar-width: none;

         &::-webkit-scrollbar {
            display: none;
         }
      }

      .modal-option {
         z-index: 1;
         width: 40px;
         height: 40px;
         display: flex;
         transition: 0.3s;
         border-radius: 50%;
         align-self: center;
         justify-content: center;
         background-color: var(--card-color);
         filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.3));

         img {
            width: 18px;
         }

         &:hover {
            cursor: pointer;
            transform: scale(0.9);
            filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
         }
      }

      .close {
         margin: -20px 0 0 72px;
      }

      .delete {
         margin: -40px 72px 0 0;
      }
   }
}

/* 1536x864 ***/
@media screen and (max-width: 1536px) and (max-height: 864px) {
   .container {
      .menu {
         width: 84px;
      }

      section {
         .material-color {
            .color-shades {
               .color-shade {
                  div:nth-child(1) {
                     height: 72%;
                  }

                  div:nth-child(2) {
                     margin: 5px 18px;

                     div {
                        p:nth-child(1) {
                           font-size: 18px;
                        }
                     }

                     img {
                        width: 28px;
                     }
                  }
               }
            }

            .color-holder {
               width: 200px;
               grid-auto-rows: 68px;
            }
         }

         .create-color {
            .solid-section {
               .solid-body {
                  .solid-color {
                     width: 50%;
                  }

                  .solid-control {
                     width: 50%;

                     .solid-attributes {
                        margin: 24px;

                        p {
                           font-size: 17px;
                        }
                     }

                     .seekbars {
                        margin-bottom: 56px;

                        ul:nth-child(2) {
                           margin-right: 10px;
                        }
                     }
                  }
               }
            }

            .gradient-section {
               .gradient-body {
                  .gradient-color {
                     width: 50%;
                  }

                  .gradient-control {
                     width: 50%;

                     .gradient-top,
                     .gradient-bottom {
                        p {
                           font-size: 17px;
                        }
                     }
                  }
               }
            }
         }

         .extract-color {
            .swatch-section {
               .swatch-body {
                  .swatch-img-chooser {
                     min-height: 200px;

                     .logo-info {
                        img {
                           width: 32px;
                        }

                        p {
                           font-size: 15px;
                           margin: 12px 0 0 8px;
                        }
                     }
                  }

                  .swatch-extract-info {
                     p {
                        font-size: medium;
                     }

                     ul {
                        li {
                           font-size: medium;
                        }
                     }
                  }
               }
            }

            .palette-section {
               .palette-body {
                  .palette-img-chooser {
                     min-height: 200px;

                     .logo-info {
                        img {
                           width: 32px;
                        }

                        p {
                           font-size: 15px;
                           margin: 12px 0 0 8px;
                        }
                     }
                  }

                  .extracted-palette {
                     width: 98%;
                     gap: 18px;
                  }

                  .palette-extract-info {
                     p {
                        font-size: medium;
                     }

                     ul {
                        li {
                           font-size: medium;
                        }
                     }
                  }
               }
            }

            .manual-section {
               .manual-body {
                  .manual-img-background {
                     .manual-color-card {
                        margin: 16px 0;

                        p {
                           font-size: medium;
                        }

                        .manual-color-hex {
                           img {
                              width: 24px;
                              height: 24px;
                           }
                        }

                        .manual-choose-image {
                           width: 40px;
                           height: 40px;

                           img {
                              width: 20px;
                              height: 20px;
                           }
                        }

                        .manual-color-rgb {
                           img {
                              width: 24px;
                              height: 24px;
                           }
                        }
                     }
                  }
               }
            }
         }

         .harmonize-color {
            .complement-container,
            .split-container,
            .analogous-container,
            .triadic-container,
            .tetradic-container {
               .complement-body,
               .split-body,
               .analogous-body,
               .triadic-body,
               .tetradic-body {
                  .colors-container {
                     margin-bottom: 8px;

                     .colors {
                        height: 100px;
                     }

                     .codes {
                        margin: 10px 0;

                        div {
                           p {
                              font-size: small;
                           }
                        }
                     }

                     .separator {
                        margin-top: 12px;
                     }

                     .info {
                        margin: 12px;
                        font-size: 12px;
                     }

                     .invert-mode {
                        margin: 6px 12px 12px 12px;

                        p {
                           font-size: medium;
                           font-weight: 700;
                        }

                        input {
                           margin: 2px 0 0 8px;
                        }

                        .save-complement,
                        .save-split,
                        .save-analogous,
                        .save-triadic,
                        .save-tetradic {
                           width: 32px;
                           height: 32px;
                           display: flex;
                           transition: 0.3s;
                           margin-left: 20px;

                           img {
                              width: 18px;
                              height: 18px;
                           }
                        }
                     }
                  }

                  .color-wheel {
                     transform: scale(0.9);
                  }
               }
            }

            .tetradic-container {
               .tetradic-body {
                  .colors-container {
                     .codes {
                        margin: 8px 0;

                        div {
                           p {
                              font-size: x-small;
                           }
                        }
                     }
                  }
               }
            }
         }

         .saved-color {
            .extraction-container {
               .extraction-items {
                  gap: 18px;
               }
            }
         }
      }
   }

   .swatch-item {
      p {
         margin: 10px 0 0 14px;
         font-size: 18px;
      }

      .swatch-hex,
      .swatch-rgb {
         p {
            margin: 0 0 0 14px;
            font-size: 16px;
         }

         img {
            width: 24px;
            height: 24px;
            margin-left: 4px;
         }
      }
   }

   .solid-item {
      .color-card {
         height: 84px;
      }

      .solid-hex {
         margin: 2px 0 0 0;

         p {
            margin-left: 10px;
            font-size: 13px;
         }

         img {
            margin: 0 6px 0 0;
            width: 20px;
            height: 20px;
         }
      }

      .solid-rgb {
         margin: 0 0 2px 0;

         p {
            margin-left: 10px;
            font-size: 13px;
         }

         img {
            margin: 0 6px 0 0;
            width: 20px;
            height: 20px;
         }
      }
   }

   .gradient-item {
      .color-card {
         height: 84px;
      }

      .code-holder {
         margin: 2px 8px;

         .color-codes {
            .color-identifier {
               width: 20px;
               height: 20px;
            }

            .codes {
               margin-left: 10px;
               margin-right: 10px;

               .code {
                  p {
                     font-size: 13px;
                  }

                  img {
                     width: 20px;
                     height: 20px;
                  }
               }
            }
         }
      }
   }

   .extract-modal {
      .modal-container {
         .extract-image {
            width: 100px;
            border-radius: 28px;
         }

         .swatch-extracts {
            width: 360px;
            height: 560px;
            margin-top: -48px;
         }

         .modal-option {
            width: 38px;
            height: 38px;

            img {
               width: 16px;
            }
         }
      }
   }

   .dual-item,
   .trial-item {
      .color-cards {
         height: 84px;
      }

      .color-codes {
         margin: 2px 10px;

         .codes {
            .code {
               p {
                  font-size: 12px;
               }

               img {
                  width: 20px;
                  height: 20px;
               }
            }
         }
      }
   }

   .quadral-item {
      .color-cards {
         height: 84px;
      }

      .color-codes {
         margin: 6px 10px;

         .codes {
            align-items: flex-start;

            p {
               font-size: 12px;
            }
         }
      }
   }
}

/* 1440x900 ***/
@media screen and (max-width: 1440px) and (max-height: 900px) {
   .container {
      .menu {
         width: 80px;

         div {
            .logo {
               width: 40px;
               height: 40px;
            }

            ul {
               li {
                  img {
                     width: 28px;
                     height: 28px;
                  }
               }
            }
         }

         .settings {
            width: 28px;
            height: 28px;
         }
      }

      section {
         .header {
            height: 90px;

            div {
               h1 {
                  font-size: 30px;
               }

               p {
                  font-size: 13px;
                  margin-bottom: 6px;
               }
            }

            img {
               width: 28px;
               height: 28px;
            }
         }

         .material-color {
            .color-shades {
               padding: 24px 20px;

               .color-shade {
                  margin: 14px;

                  div:nth-child(1) {
                     height: 73%;
                  }

                  div:nth-child(2) {
                     margin: 4px 16px;

                     div {
                        p:nth-child(1) {
                           font-size: 18px;
                        }
                     }

                     img {
                        width: 24px;
                     }
                  }
               }
            }

            .color-holder {
               width: 120px;
               margin-bottom: 1px;
               grid-auto-rows: 72px;
               grid-template-columns: repeat(1, 1fr);
            }
         }

         .create-color {
            .solid-section {
               .solid-header {
                  .solid-header-title {
                     img {
                        width: 28px;
                        height: 28px;
                     }

                     p {
                        font-size: 19px;
                     }
                  }

                  .solid-alpha-control {
                     p {
                        font-size: 19px;
                     }

                     input {
                        margin-top: 4px;
                        transform: scale(0.9);
                     }
                  }
               }

               .solid-body {
                  .solid-color {
                     width: 45%;
                  }

                  .solid-control {
                     width: 55%;

                     .solid-attributes {
                        margin: 24px;

                        p {
                           font-size: 17px;
                        }

                        img {
                           width: 24px;
                           height: 24px;
                        }
                     }

                     .seekbars {
                        margin-bottom: 56px;

                        ul:nth-child(2) {
                           margin-right: 6px;
                        }
                     }
                  }
               }
            }

            .gradient-section {
               .gradient-header {
                  .gradient-header-title {
                     img {
                        width: 28px;
                        height: 28px;
                     }

                     p {
                        font-size: 19px;
                     }
                  }

                  .gradient-alpha-control {
                     p {
                        font-size: 19px;
                     }

                     input {
                        margin-top: 4px;
                        transform: scale(0.9);
                     }
                  }
               }

               .gradient-body {
                  .gradient-color {
                     width: 45%;
                  }

                  .gradient-control {
                     width: 55%;

                     .gradient-top,
                     .gradient-bottom {
                        p {
                           font-size: 17px;
                        }

                        img {
                           width: 24px;
                           height: 24px;
                        }
                     }
                  }
               }
            }
         }

         .extract-color {
            .swatch-section {
               .swatch-header {
                  .swatch-header-title {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 28px;
                        height: 28px;
                     }
                  }

                  .swatch-reset {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 24px;
                        height: 24px;
                     }
                  }
               }

               .swatch-body {
                  .swatch-img-chooser {
                     min-height: 180px;

                     .logo-info {
                        img {
                           width: 32px;
                        }

                        p {
                           font-size: 15px;
                           margin: 12px 0 0 8px;
                        }
                     }
                  }

                  .swatch-extract-info {
                     p {
                        font-size: medium;
                     }

                     ul {
                        li {
                           font-size: medium;
                        }
                     }
                  }
               }
            }

            .palette-section {
               .palette-header {
                  .palette-header-title {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 28px;
                        height: 28px;
                     }
                  }

                  .palette-reset {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 24px;
                        height: 24px;
                     }
                  }
               }

               .palette-body {
                  .palette-img-chooser {
                     min-height: 180px;

                     .logo-info {
                        img {
                           width: 32px;
                        }

                        p {
                           font-size: 15px;
                           margin: 12px 0 0 8px;
                        }
                     }
                  }

                  .extracted-palette {
                     width: 95%;
                     gap: 20px;
                     grid-template-columns: repeat(2, 1fr);
                  }

                  .palette-extract-info {
                     p {
                        font-size: medium;
                     }

                     ul {
                        li {
                           font-size: medium;
                        }
                     }
                  }
               }
            }

            .manual-section {
               .manual-header {
                  .manual-header-title {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 28px;
                        height: 28px;
                     }
                  }

                  .manual-reset {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 24px;
                        height: 24px;
                     }
                  }
               }

               .manual-body {
                  .manual-img-background {
                     .manual-color-card {
                        margin: 16px 0;

                        p {
                           font-size: medium;
                        }

                        .manual-color-hex {
                           img {
                              width: 24px;
                              height: 24px;
                           }
                        }

                        .manual-choose-image {
                           width: 40px;
                           height: 40px;

                           img {
                              width: 20px;
                              height: 20px;
                           }
                        }

                        .manual-color-rgb {
                           img {
                              width: 24px;
                              height: 24px;
                           }
                        }
                     }
                  }
               }
            }
         }

         .harmonize-color {
            .complement-container,
            .split-container,
            .analogous-container,
            .triadic-container,
            .tetradic-container {
               .complement-header,
               .split-header,
               .analogous-header,
               .triadic-header,
               .tetradic-header {
                  .complement-header-title,
                  .split-header-title,
                  .analogous-header-title,
                  .triadic-header-title,
                  .tetradic-header-title {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 28px;
                        height: 28px;
                     }
                  }
               }

               .complement-body,
               .split-body,
               .analogous-body,
               .triadic-body,
               .tetradic-body {
                  .colors-container {
                     margin-bottom: 8px;

                     .colors {
                        height: 100px;
                     }

                     .codes {
                        margin: 10px 0;

                        div {
                           p {
                              font-size: small;
                           }
                        }
                     }

                     .separator {
                        margin-top: 12px;
                     }

                     .info {
                        margin: 12px;
                        font-size: 12px;
                     }

                     .invert-mode {
                        margin: 6px 12px 12px 12px;

                        p {
                           font-size: medium;
                           font-weight: 700;
                        }

                        input {
                           margin: 2px 0 0 8px;
                        }

                        .save-complement,
                        .save-split,
                        .save-analogous,
                        .save-triadic,
                        .save-tetradic {
                           width: 32px;
                           height: 32px;
                           display: flex;
                           transition: 0.3s;
                           margin-left: 20px;

                           img {
                              width: 18px;
                              height: 18px;
                           }
                        }
                     }
                  }

                  .color-wheel {
                     transform: scale(0.9);
                  }
               }
            }

            .tetradic-container {
               .tetradic-body {
                  .colors-container {
                     .codes {
                        margin: 8px 0;

                        div {
                           p {
                              font-size: 11px;
                           }
                        }
                     }
                  }
               }
            }
         }

         .saved-color {
            .solgrad-container,
            .extraction-container,
            .harmony-container,
            .preset-container {
               .solgrad-header,
               .extraction-header,
               .harmony-header,
               .preset-header {
                  .solgrad-header-title,
                  .extraction-header-title,
                  .harmony-header-title,
                  .preset-header-title {
                     p {
                        font-size: 19px;
                     }

                     img {
                        width: 28px;
                        height: 28px;
                     }
                  }

                  .solgrad-options,
                  .extraction-options,
                  .harmony-options,
                  .preset-options {
                     select {
                        font-size: 15px;
                     }
                  }
               }
            }

            .extraction-container {
               .extraction-items {
                  gap: 18px;
               }
            }
         }
      }
   }

   .swatch-item {
      p {
         margin: 10px 0 0 14px;
         font-size: 18px;
      }

      .swatch-hex,
      .swatch-rgb {
         p {
            margin: 0 0 0 14px;
            font-size: 16px;
         }

         img {
            width: 24px;
            height: 24px;
            margin-left: 4px;
         }
      }
   }

   .solid-item {
      .color-card {
         height: 84px;
      }

      .solid-hex {
         margin: 2px 0 0 0;

         p {
            margin-left: 8px;
            font-size: 14px;
         }

         img {
            margin: 0 8px 0 0;
            width: 22px;
            height: 22px;
         }
      }

      .solid-rgb {
         margin: 0 0 2px 0;

         p {
            margin-left: 8px;
            font-size: 14px;
         }

         img {
            margin: 0 8px 0 0;
            width: 22px;
            height: 22px;
         }
      }
   }

   .gradient-item {
      .color-card {
         height: 84px;
      }

      .code-holder {
         margin: 2px 8px;

         .color-codes {
            .color-identifier {
               width: 20px;
               height: 20px;
            }

            .codes {
               margin-left: 10px;
               margin-right: 10px;

               .code {
                  p {
                     font-size: 13px;
                  }

                  img {
                     width: 20px;
                     height: 20px;
                  }
               }
            }
         }
      }
   }

   .extract-modal {
      .modal-container {
         .extract-image {
            width: 94px;
            border-radius: 28px;
         }

         .swatch-extracts {
            width: 340px;
            height: 540px;
            margin-top: -48px;
         }

         .palette-extracts {
            width: 340px;
            height: 540px;
            margin-top: -48px;
         }

         .modal-option {
            width: 38px;
            height: 38px;

            img {
               width: 16px;
            }
         }
      }
   }

   .dual-item {
      .color-cards {
         height: 84px;
      }

      .color-codes {
         margin: 2px 12px;

         .codes {
            .code {
               p {
                  font-size: 14px;
               }

               img {
                  width: 22px;
                  height: 22px;
               }
            }
         }
      }
   }

   .trial-item {
      .color-cards {
         height: 84px;
      }

      .color-codes {
         margin: 2px 8px;

         .codes {
            .code {
               p {
                  font-size: 11px;
               }

               img {
                  width: 18px;
                  height: 18px;
               }
            }
         }
      }
   }

   .quadral-item {
      .color-cards {
         height: 84px;
      }

      .color-codes {
         margin: 6px 8px;

         .codes {
            align-items: flex-start;

            p {
               font-size: 12px;
            }
         }
      }
   }
}
