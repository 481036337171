@mixin centerItems {
   display: flex;
   align-items: center;
   justify-content: center;
}

@mixin iconDropShadow($color) {
   filter: drop-shadow(0 4px 4px $color);
}

@mixin scrollBar {
   &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      
   }
   &::-webkit-scrollbar-track {
      background-color: transparent;
   }
   &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      transition: var(--transition);
      background-color: rgba(var(--text-color-rgb), 0.8);
   }
}
